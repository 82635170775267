import { OrderService } from "@services";
import { useMutation, UseMutationResult } from "react-query";

export const useCreateOrder = (): UseMutationResult<any> => {
  return useMutation({
    mutationKey: ["create-order"],
    mutationFn: (request: any) => OrderService.createOrder(request),
    retry: false,
  });
};

export const useCreatePreference = (): UseMutationResult<any> => {
  return useMutation({
    mutationKey: ["create-preference"],
    mutationFn: (request: any) => OrderService.createPreference(request),
    retry: false,
  });
};

export const useSignIn = (): UseMutationResult<any> => {
  return useMutation({
    mutationKey: ["sign-in"],
    mutationFn: (request: any) => OrderService.signIn(request),
    retry: false,
  });
};

export const useReset = (): UseMutationResult<any> => {
  return useMutation({
    mutationKey: ["reset"],
    mutationFn: (request: any) => OrderService.reset(request),
    retry: false,
  });
};
