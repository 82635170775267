import { TLotteryItem } from "@types";
import { Http } from "../libs/http";

class LotteryServices {
  private http;

  // constructor() {
  //   this.http = new Http().getInstance();
  // }

  getAll(): Promise<TLotteryItem[]> {
    this.http = new Http().getInstance();
    return this.http.get("/active-lotteries");
  }

  getAllAdmin(): Promise<TLotteryItem[]> {
    this.http = new Http().getInstance();
    return this.http.get("/lottery");
  }

  getOrders(): Promise<TLotteryItem[]> {
    this.http = new Http().getInstance();
    return this.http.get("/order");
  }

  getAllFinished(): Promise<TLotteryItem[]> {
    this.http = new Http().getInstance();
    return this.http.get("/finished-lotteries");
  }

  getDetail(request: any): Promise<any> {
    this.http = new Http().getInstance();
    return this.http.get(`/active-lotteries/${request.id}`);
  }

  getDetailAdmin(request: any): Promise<any> {
    this.http = new Http().getInstance();
    if (request) {
      return this.http.get(`/lottery/${request.id}`);
    }
  }

  getDetailFinished(request: any): Promise<any> {
    this.http = new Http().getInstance();
    return this.http.get(`/finished-lotteries/${request.id}`);
  }

  getUserByCellphone(request: any): Promise<any> {
    this.http = new Http().getInstance();
    return this.http.get(`/customer/cellphone/${request.cellphone}`);
  }

  getWinner(request: any): Promise<any> {
    this.http = new Http().getInstance();
    return this.http.get(
      `/find-winner?lotteryId=${request.id}&winnerNumber=${request.number}`
    );
  }

  getPurchases(request: any): Promise<any> {
    this.http = new Http().getInstance();
    return this.http.get(
      `/purchases?cellPhone=${request.cellphone}&offset=${request.offset}&limit=1`
    );
  }

  createLottery(body: any): Promise<any> {
    this.http = new Http().getInstance();
    return this.http.post("/lottery", body);
  }

  updateLottery(body: any, id: any): Promise<any> {
    this.http = new Http().getInstance();
    return this.http.put(`/lottery/${id}`, body);
  }

  updateOrder(body: any, id: any): Promise<any> {
    this.http = new Http().getInstance();
    return this.http.put(`/order/${id}`, body);
  }

  publishWS(id: any): Promise<any> {
    this.http = new Http(process.env.WS_URL).getInstance();
    return this.http.post("/", { message: id });
  }

  getNumbers(request: any): Promise<any> {
    this.http = new Http().getInstance();
    return this.http.get(`/order-numbers/${request.id}`);
  }

  getNumbersMP(request: any): Promise<any> {
    this.http = new Http().getInstance();
    return this.http.get(`/order-numbers-preference/${request.preferenceId}`);
  }
}

export const LotteryService = new LotteryServices();
