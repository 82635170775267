import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Fab,
  LinearProgress,
  LinearProgressProps,
  Link,
  Modal,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { PriceChange } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { termsAndConditions } from "../../constants/termsAndConditions";
import { CounterInput, LayoutTheme } from "@components";
import Ably from "ably";
import { useParams } from "react-router-dom";
import { useGetDetail, usePublishWS } from "../../hooks/queries/lottery";
import axios from "axios";
import { DateTimeField } from "@mui/x-date-pickers";
import moment from "moment-timezone";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  height: "80vh",
  overflow: "scroll",
};

export const GiveawayDetail = () => {
  const navigate = useNavigate();
  const widgets = [20, 100, 500];
  const [count, setCount] = useState(0);
  const [min, setMin] = useState(0);
  const [stock, setStock] = useState(1);
  const [errorMsg, setErrorMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [notAvailable, setNotAvailable] = useState(false);
  const terms = termsAndConditions;
  const { id } = useParams();
  const lastElementRef = useRef(null);
  const { data, isLoading, isError, isSuccess } = useGetDetail({
    id,
  });

  const {
    mutate: publish,
    isSuccess: isSuccessWS,
    isLoading: isLoadingWS,
    isError: isErrorWS,
    data: dataWS,
  } = usePublishWS();

  useEffect(() => {
    if (isSuccess) {
      setNotAvailable(false);
      setStock(data?.stock);
      setMin(data?.minQuantityTickets);
      setCount(data?.minQuantityTickets);
      // Agregar 24 horas a la fecha original
      const momentoActual = moment().utc();
      const momentoComparar = moment(data?.drawDate).add(5, "hours");

      const diferenciaHoras = momentoComparar.diff(momentoActual, "hours");

      if (diferenciaHoras === 2) {
        setNotAvailable(true);
      }
    }
  }, [isLoading]);

  const handleChange1 = (event: any) => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleAdd = (countFab: number) => {
    debugger;
    setErrorMsg("");
    if (count + countFab > 10000) {
      setErrorMsg("El número máximo por compra es de 10.000");
    } else if (count + countFab > stock) {
      setErrorMsg("Ha superado el límite de números disponibles");
    }
    setCount((prevState) => prevState + countFab);
  };

  useEffect(() => {
    if (id) {
      publish(id);
    }
  }, [id]);

  useEffect(() => {
    if (isSuccessWS) {
      setStock(dataWS?.stock);
    }
  }, [isLoadingWS]);

  useEffect(() => {
    if (id) {
      const ably = new Ably.Realtime(process.env.ABLY_API_KEY);

      // Replace 'example_channel' with your desired channel name
      const channel = ably.channels.get(id);

      // Listen for messages on the channel
      channel.subscribe("example_event", (message) => {
        setStock(message.data?.stock);
      });

      return () => {
        // Cleanup subscriptions when the component unmounts
        channel.unsubscribe();
        //ably.close();
      };
    }
  }, [id]);

  useEffect(() => {
    if (lastElementRef.current) {
      lastElementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [lastElementRef.current]);

  return (
    <LayoutTheme titleOne="Detalle del" titleTwo="Sorteo">
      <Box display="flex" flexDirection="column" alignItems="center">
        {isLoading && !isSuccess ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              height: "200px",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Card
            sx={{
              width: "100%",
              padding: "16px",
            }}
          >
            {notAvailable && (
              <Box pb={2}>
                <LinearWithValueLabel />
              </Box>
            )}
            {/* <Box
              sx={{
                width: "100%",
                height: "400px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardMedia
                component="img"
                sx={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  height: "auto",
                  width: "auto",
                }}
                image={data?.imageURL}
                alt="green iguana"
              />
            </Box> */}

            <CardContent>
              <Box
              //ref={lastElementRef}
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <Typography mb={1} fontWeight={600} ref={lastElementRef}>
                  Mínimo de números
                </Typography>
                <NeonHeading>
                  <p style={{ margin: 0 }}>{min}</p>
                </NeonHeading>
              </Box>

              <Box
                mt={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{ flexDirection: { xs: "column", md: "row" } }}
              >
                <Typography gutterBottom variant="h5" component="div">
                  {data?.name} - Juega con la {data?.nameLottery}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    flexDirection: { xs: "column", md: "row" },
                  }}
                  mt={2}
                  mb={2}
                >
                  <Box my={1}>
                    <CalendarMonthIcon sx={{ mr: 1, my: 0.5 }} />
                    <DateTimeField
                      variant="standard"
                      size="small"
                      label="Fecha del sorteo"
                      ampm
                      defaultValue={moment(data?.drawDate).add(5, "hour")}
                    />
                  </Box>
                  <Box my={1}>
                    <PriceChange
                      sx={{
                        mr: 1,
                        my: 0.5,
                        ".MuiInputBase-input": { color: "black !important" },
                      }}
                    />
                    <TextField
                      id="input-with-sx"
                      label="Valor"
                      variant="standard"
                      value={`$${data.pricePerTicket.toLocaleString()} COP`}
                    />
                  </Box>
                </Box>
              </Box>
              {/* <Typography variant="h6" color="text.secondary">
                {data?.description}
              </Typography> */}
              <Box
                sx={{
                  marginTop: "8px",
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  gap: 1.5,
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {widgets.map((i) => (
                  <Fab
                    onClick={() => handleAdd(i)}
                    variant="extended"
                    sx={{
                      width: { xs: "100%", md: "100px" },
                      backgroundColor: "#55A6F6",
                      color: "white",
                      "&.MuiButtonBase-root:hover": {
                        backgroundColor: "#55A6F6",
                      },
                    }}
                  >
                    <AddIcon sx={{ mr: 1 }} />
                    {i}
                  </Fab>
                ))}
                <Box textAlign="center">
                  <label>{`Ingrese la cantidad`}</label>
                  <CounterInput
                    setCount={setCount}
                    min={min}
                    count={count}
                    stock={stock}
                    setErrorMsg={setErrorMsg}
                    errorMsg={errorMsg}
                  />
                </Box>
              </Box>
              <Button
                variant="contained"
                color="success"
                disabled={errorMsg !== "" || notAvailable || count < min}
                sx={{
                  marginTop: "24px",
                  width: "100%",
                  marginBottom: "16px",
                }}
                onClick={() => {
                  navigate(`/sorteos-activos/${id}/comprar`, {
                    state: {
                      total: count * data.pricePerTicket,
                      name: data.name,
                      count,
                      pricePerTicket: data.pricePerTicket,
                    },
                  });
                }}
              >
                {`Participar por $${(
                  count * data.pricePerTicket
                ).toLocaleString()}`}
              </Button>
              <Typography
                variant="caption"
                textAlign="center"
                sx={{ opacity: 0.8, marginTop: "16px" }}
              >
                Ver&nbsp;
                <Link color="primary">
                  <span onClick={handleChange1}>Términos & Condiciones</span>
                </Link>
                .
              </Typography>
              <div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Términos y Condiciones
                    </Typography>
                    <div>
                      <div
                        style={{ textAlign: "justify" }}
                        dangerouslySetInnerHTML={{ __html: terms.text }}
                      />
                      <p>{`PREMIO: ${data?.name}`}</p>
                      <p>
                        COMO SE DETALLA EN LAS CONDICIONES DE PARTICIPACIÓN.
                      </p>
                    </div>
                    {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      {terms.text}
                    </Typography> */}
                  </Box>
                </Modal>
              </div>
            </CardContent>
          </Card>
        )}
      </Box>
    </LayoutTheme>
  );
};

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function LinearWithValueLabel() {
  const [progress, setProgress] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 10 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel value={progress} />
      <Box textAlign="center">
        <h2>Cargando y... ¡Ya no hay números disponibles!</h2>
      </Box>
    </Box>
  );
}

const GlobalStyle = styled("style")(({ theme }) => ({
  // Define las variables CSS globales
  ":root": {
    "--neon-text-color": "#f40",
    "--neon-border-color": "#08f",
  },
}));

const NeonHeading = styled("div")({
  //"& p, span": {
  width: "110px",
  textAlign: "center",
  fontSize: "20px",
  fontWeight: 600,
  fontStyle: "italic",
  color: "#fff",
  padding: "10px",
  border: "2px solid #fff",
  borderRadius: "10px",
  textTransform: "uppercase",
  animation: "flicker 1.5s infinite alternate",
  //},

  "@keyframes flicker": {
    "0%, 19%, 21%, 23%, 25%, 54%, 56%, 100%": {
      boxShadow: `
      0 0 5px #fff,inset 0 0 #fff,0 0 1rem #08f,inset 0 0 #08f,0 0 1rem #08f,inset 0 0`,
    },
    "20%, 24%, 55%": {
      textShadow: "none",
      boxShadow: "none",
    },
  },
});
