import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  LinearProgress,
  MenuItem,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useFormControlContext } from "@mui/base/FormControl";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { useGetUserByCellphone } from "../hooks/queries/lottery";
import { datJSON } from "../utils/cities";

export const UserForm = ({
  register,
  handleSubmit,
  control,
  errors,
  title,
  origin,
  setCellphone,
  cellphone,
  isLoading,
  data,
  setValue,
  showTerms = true,
  errorMsg,
  disabledInputs = false
}: any) => {
  const regex = /^[0-9]{10}$/;
  const departmentsFilter = datJSON.map((i) => ({
    id: i.c_digo_dane_del_departamento,
    name_dep: i.departamento,
  }));
  const uniqueArrayDep = departmentsFilter.filter(
    (obj, index, self) => index === self.findIndex((el) => el.id === obj.id)
  );
  const arrayAll = datJSON;

  const [departments, setDepartments] = useState(uniqueArrayDep);
  const navigate = useNavigate();
  const [departmentSelected, setDepartmentSelected] = useState<any>(null);
  const [cities, setCities] = useState([]);

  const handlePhoneChange = (e) => {
    if (e.target.value.length <= 10) {
      setCellphone(e.target.value);
    }
    //setCellphone(e.target.value); // Actualizar el número de celular en el estado local
  };

  // useEffect(() => {
  //   fetch("https://api-colombia.com/api/v1/Department")
  //     .then((response) => response.json())
  //     .then((response) => {
  //       const sort = response.sort((a: any, b: any) => a.id - b.id);
  //       setDepartments(sort);
  //     })
  //     .catch((errors) => console.log(errors));
  // }, []);

  useEffect(() => {
    if (departmentSelected) {
      const filteredArray = arrayAll.filter(
        (item) =>
          item.c_digo_dane_del_departamento === parseInt(departmentSelected)
      );
      setCities(filteredArray);
      // fetch(
      //   `https://api-colombia.com/api/v1/Department/${departmentSelected}/cities`
      // )
      //   .then((response) => response.json())
      //   .then((response) => {
      //     const sort = response.sort(function (a: any, b: any) {
      //       if (a.name < b.name) {
      //         return -1;
      //       }
      //       if (a.name > b.name) {
      //         return 1;
      //       }
      //       return 0;
      //     });
      //     setCities(sort);
      //   })
      //   .catch((errors) => console.log(errors));
      // setDepartmentSelected(null);
    }
  }, [departmentSelected]);

  const handleDepartment = (e: any) => {
    setDepartmentSelected(e.target.value.id);
  };

  return (
    <Box>
      {title && (
        <>
          <Typography
            variant="h6"
            align="center"
            margin="dense"
            fontWeight={600}
          >
            {title}
          </Typography>
          <br />
        </>
      )}
      <span>
        El celular que registre debe tener WhatsApp. 
        Procure no registrar este mismo número para realizar compras a nombre de otra persona.
      </span>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Label sx={{ margin: "0px" }}>
            <Typography
              color={
                cellphone.length < 10 || !regex.test(cellphone)
                  ? "error"
                  : "inherit"
              }
            >
              Celular
            </Typography>
          </Label>
          <TextField
            required
            autoComplete="off"
            id="cellPhone"
            size="small"
            fullWidth
            margin="dense"
            disabled={disabledInputs}
            onChange={handlePhoneChange}
            inputProps={{ maxLength: 10 }}
            //{...register("cellPhone" as never)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Label sx={{ margin: "0px" }}>
            <Typography color={errors.firstName ? "error" : "inherit"}>
              Nombre
            </Typography>
          </Label>
          <TextField
            disabled={!isLoading || disabledInputs}
            required
            size="small"
            id="firstName"
            margin="dense"
            fullWidth
            {...register("firstName" as never)}
            //error={errors.name ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Label sx={{ margin: "0px" }}>
            <Typography color={errors.lastName ? "error" : "inherit"}>
              Apellidos
            </Typography>
          </Label>
          <TextField
            required
            disabled={!isLoading || disabledInputs}
            id="lastName"
            size="small"
            margin="dense"
            fullWidth
            {...register("lastName" as never)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Label sx={{ margin: "0px" }}>
            <Typography color={errors.email ? "error" : "inherit"}>
              Email
            </Typography>
          </Label>
          <TextField
            disabled={!isLoading || disabledInputs}
            required
            id="email"
            size="small"
            fullWidth
            margin="dense"
            {...register("email" as never)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Label sx={{ margin: "0px" }}>
            <Typography color={errors.department ? "error" : "inherit"}>
              Departamento
            </Typography>
          </Label>
          <TextField
            required
            disabled={!isLoading || disabledInputs}
            select
            size="small"
            id="department"
            fullWidth
            margin="dense"
            {...register("department" as never, {
              onChange: handleDepartment,
            })}
          >
            {departments.map((option: any) => (
              <MenuItem key={option.id} value={option}>
                {option.name_dep}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Label sx={{ margin: "0px" }}>
            <Typography color={errors.city ? "error" : "inherit"}>
              Ciudad
            </Typography>
          </Label>
          <TextField
            required
            disabled={!isLoading || disabledInputs}
            select
            size="small"
            id="city"
            fullWidth
            margin="dense"
            {...register("city" as never)}
          >
            {cities.map((option: any) => (
              <MenuItem key={option?.id} value={option?.municipio}>
                {option?.municipio}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Label sx={{ margin: "0px" }}>
            <Typography color={errors.address ? "error" : "inherit"}>
              Dirección
            </Typography>
          </Label>
          <TextField
            required
            disabled={!isLoading || disabledInputs}
            id="address"
            fullWidth
            size="small"
            margin="dense"
            {...register("address" as never)}
          />
        </Grid>
        {showTerms && <Grid item xs={12} display="flex" flexDirection="column">
          <FormControlLabel
            control={
              <Controller
                control={control}
                name={"acceptTermsAndConditions" as never}
                defaultValue={false as unknown as undefined as never}
                //inputRef={register()}
                render={({ field: { onChange } }) => (
                  <Checkbox
                    color="primary"
                    onChange={(e) => onChange(e.target.checked)}
                  />
                )}
              />
            }
            label={
              <Typography
                color={errors.acceptTermsAndConditions ? "error" : "inherit"}
              >
                Acepto haber leído lo Términos y Condiciones *
              </Typography>
            }
          />
          <br />
          <p>**Valide los datos proporcionados antes de continuar</p>
        </Grid>}
      </Grid>

      {origin && origin.name === "customer" && (
        <Box mt={3} textAlign="right">
          <Button
            variant="contained"
            color="primary"
            disabled={errorMsg !== "" || origin.isLoad}
            onClick={handleSubmit(origin.submit)}
          >
            Registrar
          </Button>
        </Box>
      )}
    </Box>
  );
};

const Label = styled(
  ({
    children,
    className,
  }: {
    children?: React.ReactNode;
    className?: string;
  }) => {
    const formControlContext = useFormControlContext();
    const [dirty, setDirty] = useState(false);

    useEffect(() => {
      if (formControlContext?.filled) {
        setDirty(true);
      }
    }, [formControlContext]);

    if (formControlContext === undefined) {
      return <p>{children}</p>;
    }

    const { error, required, filled } = formControlContext;
    const showRequiredError = dirty && required && !filled;

    return (
      <p
        className={clsx(className, error || showRequiredError ? "invalid" : "")}
      >
        {children}
        {required ? " *" : ""}
      </p>
    );
  }
)`
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 0.875rem;
  margin-bottom: 4px;

  &.invalid {
    color: red;
  }
`;
