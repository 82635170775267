import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from "material-react-table";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useLottery, useLotteryAdmin } from "@hooks";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import moment, { MomentInput } from "moment-timezone";
import "moment/locale/es";

export const Lottery = () => {
  const navigate = useNavigate();
  const { data, isLoading, isError, refetch, isRefetching } = useLotteryAdmin();

  const [dataLot, setDataLot] = useState<any>([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      setDataLot(data);
    }
  }, [isLoading]);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    //column definitions...
    () => [
      {
        accessorKey: "id",
        header: "Id sorteo",
        size: 220,
        Cell: ({ renderedCellValue, row }) => (
          <span
            onClick={() => navigate(`/admin/sorteo/${row.original.id}`)}
            style={{ cursor: "pointer" }}
            title="Ver detalle"
          >
            {row.original.id}
          </span>
        ),
      },
      {
        accessorKey: "name",
        header: "Titulo",
        size: 230,
        Cell: ({ renderedCellValue, row }) => (
          <span
            onClick={() => navigate(`/admin/sorteo/${row.original.id}`)}
            style={{ cursor: "pointer" }}
            title="Ver detalle"
          >
            {renderedCellValue as string}
          </span>
        ),
      },
      {
        accessorKey: "drawDate",
        header: "Estado",
        size: 250,
        Cell: ({ renderedCellValue, row }) => {
          const status: any =
            moment().isBefore(renderedCellValue as MomentInput) ||
            moment().isSame(renderedCellValue as MomentInput)
              ? { msg: "Activo", color: "success" }
              : { msg: "Concluido", color: "error" };
          return <Chip color={status.color} size="small" label={status.msg} />;
        },
      },
      {
        accessorKey: "nameLottery",
        header: "Loteria",
        size: 180,
      },
      {
        accessorKey: "drawDate",
        header: "Fecha",
        size: 250,
        Cell: ({ cell }) => (
          <span>
            {moment(cell.getValue() as string)
              .add(5, "hour")
              .locale("es")
              .format("DD/MMMM/YYYY - h:mm a")}
          </span>
        ),
      },
    ],
    []
    //end
  );

  const table = useMaterialReactTable({
    columns,
    data: data || [],
    initialState: { showColumnFilters: false },
    manualFiltering: false,
    manualPagination: false, //turn off built-in client-side pagination
    manualSorting: false, //turn off built-in client-side sorting
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    renderTopToolbarCustomActions: () => (
      <Tooltip arrow title="Refresh Data">
        <IconButton onClick={() => refetch()}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    ),
    //rowCount: dataLot?.length ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return (
    <Box padding={3} width="inherit">
      <Box display="flex" justifyContent="space-between" mb={3}>
        <Typography gutterBottom variant="h5" component="div" fontWeight={600}>
          Listado de sorteos
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddCircleOutlineIcon />}
          sx={{ fontWeight: 600 }}
          onClick={() => navigate(`/admin/crear-sorteo`)}
        >
          Agregar sorteo
        </Button>
      </Box>
      <MaterialReactTable table={table} />
    </Box>
  );
};
