import React, { ChangeEvent, useEffect, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  TextField,
  Button,
  Box,
  Grid,
  Alert,
  IconButton,
  Tooltip,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  tableCellClasses,
  Modal,
  FormControl,
  InputLabel,
  Select,
  Chip,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import zlib from "zlib";
import moment from "moment-timezone";
import {
  useCreateLottery,
  useGetDetailAdmin,
  useUpdateLottery,
} from "../hooks/queries/lottery";
import { Link, useNavigate, useParams } from "react-router-dom";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { useGetWinner } from "../hooks/queries/lottery";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import imageCompression from "browser-image-compression";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const schema = yup.object().shape({
  title: yup.string(),
  description: yup.string(),
  lottery: yup.string(),
  minTickets: yup.number(),
  extraDraws: yup.array().of(
    yup.object().shape({
      number: yup
        .string()
        .matches(
          /^[0-9]{6}$/,
          "El número debe contener solo 6 dígitos númericos"
        )
        .required("El número es obligatorio"),
      gift: yup.string().required("El regalo es obligatorio"),
    })
  ),
});

export const GiveawayForm: React.FC = () => {
  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema, undefined, undefined),
  });

  const [photoConverted, setPhotoConverted] = useState<any>();
  const [rows, setRows] = useState<any>([]);
  const [rowsExtras, setRowsExtras] = useState<any>([]);
  const [rowsExtrasInit, setRowsExtrasInit] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");

  const [unitPrice, setUnitPrice] = useState<any>("");
  const [stepper, setStepper] = useState<any>("init");
  const [number, setNumber] = useState<any>("");
  const [winner, setWinner] = useState<any>("");
  const [errorMsg, setErrorMsg] = useState<any>({ type: "", msg: "" });
  const [unitPriceFormat, setUnitPriceFormat] = useState<any>(0);
  const [drawDate, setDrawDate] = useState<any>(moment());
  const { id } = useParams();
  const navigate = useNavigate();

  const { mutate, isError, isLoading, isSuccess } = useCreateLottery();
  const {
    mutate: update,
    isError: isErrorUpdate,
    isLoading: isLoadingUpdate,
    isSuccess: isSuccessUpdate,
  } = useUpdateLottery(id);
  const {
    data,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
    isSuccess: isSuccessDetail,
    refetch,
  } = useGetDetailAdmin({
    id,
  });

  const {
    data: dataWinner,
    isLoading: isLoadingWinner,
    isError: isErrorDetailWinner,
    isSuccess: isSuccessDetailWinner,
    refetch: refetchWinner,
  } = useGetWinner({
    id,
    number,
  });

  const handleOpen = (id: any) => {
    setOpen(true);
  };

  const getWinner = () => {
    refetchWinner();
  };

  const onChangeNumber = (e: any) => setNumber(e.target.value);

  const onChangeWinner = (e: any) => setWinner(e.target.value);

  const handleClose = () => {
    setStepper("init");
    refetch();
    setOpen(false);
    window.location.reload();
  };

  useEffect(() => {
    if (isSuccessDetail && data) {
      setValue("title" as never, data?.name as never);
      setValue("description" as never, data?.description as never);
      setValue("lottery" as never, data?.nameLottery as never);
      setValue("minTickets" as never, data?.minQuantityTickets as never);
      //setValue("extraDraws" as never, data?.extraDraws as never);
      setUnitPrice(
        String(data?.pricePerTicket).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      );
      setDrawDate(moment(data?.drawDate).add(5, "hour"));
      if (data?.winnerObj) {
        const rows = [
          createData(
            `${data?.winnerObj?.firstName} ${data?.winnerObj?.lastName}`,
            data?.winnerObj?.email,
            data?.winnerObj?.cellPhone,
            data?.winnerObj?.department,
            data?.winnerObj?.city,
            data?.winnerObj?.address
          ),
        ];
        setRows(rows);
      }
      let rowsExtra = [];
      setRowsExtrasInit(data?.extraDraws);
      data?.extraDraws?.forEach((i) => {
        if (i?.customer && i?.orderId) {
          const row = createDataExtra(
            i.number,
            i.gift,
            `${i?.customer?.firstName} ${i?.customer?.lastName}`,
            i?.customer?.email,
            i?.customer?.cellPhone,
            i?.customer?.department,
            i?.customer?.city,
            i?.customer?.address,
            i?.orderId
          );
          rowsExtra.push(row);
        } else {
          const row = createDataExtra(
            i.number,
            i.gift,
            "",
            "",
            "",
            "",
            "",
            "",
            ""
          );
          rowsExtra.push(row);
        }
      });
      setRowsExtras(rowsExtra);
    }
  }, [isLoadingDetail]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "extraDraws" as never,
  });

  useEffect(() => {
    if (isSuccessDetailWinner) {
      if (dataWinner === 1) {
        setStepper("winner");
        //refetch();
      }
      if (dataWinner === 0) {
        setStepper("assign");
      }
    }
  }, [isLoadingWinner]);

  const handleSetImage = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    const options = {
      maxSizeMB: 1, // Tamaño máximo en MB
      maxWidthOrHeight: 700, // Ancho o altura máxima en píxeles
      useWebWorker: true, // Usar un web worker para mejorar el rendimiento
    };

    try {
      const compressedFile = await imageCompression(file, options);
      const reader = new FileReader();
      reader.readAsDataURL(compressedFile);
      reader.onloadend = () => {
        setPhotoConverted(reader.result);
      };
    } catch (error) {
      console.error("Error al comprimir la imagen:", error);
    }
  };

  useEffect(() => {
    setErrorMsg({ type: "", msg: "" });
    if (isSuccess) {
      setErrorMsg({
        type: "success",
        msg: "El sorteo se ha creado exitosamente.",
      });
      setTimeout(() => navigate("/admin"), 3000);
    }
    if (isError) {
      setErrorMsg({
        type: "error",
        msg: "Ha ocurrido un error, intenta de nuevo",
      });
    }
  }, [isLoading]);

  useEffect(() => {
    setErrorMsg({ type: "", msg: "" });
    if (isSuccessUpdate) {
      refetch();
      setErrorMsg({
        type: "success",
        msg: "El sorteo se ha actualizado exitosamente",
      });
      handleClose();
    }
    if (isError) {
      setErrorMsg({
        type: "error",
        msg: "Ha ocurrido un error, intenta de nuevo",
      });
      handleClose();
    }
  }, [isLoadingUpdate]);

  const handleUnitPriceChange = (event) => {
    const { value } = event.target;
    const formattedValue = value
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setUnitPrice(formattedValue);
    setUnitPriceFormat(value.replace(/\D/g, ""));
  };

  const onSubmit = (data: any) => {
    if (!id) {
      const body = {
        name: data?.title,
        description: data?.description,
        drawDate: moment(drawDate).add(-5, "hour"),
        pricePerTicket: unitPriceFormat,
        minQuantityTickets: data?.minTickets,
        imageURL: photoConverted,
        nameLottery: data?.lottery,
      };
      if (data?.extraDraws && data?.extraDraws?.length > 0) {
        body["extraDraws"] = data?.extraDraws;
      }
      mutate(body);
    } else {
      const body = {
        drawDate: moment(drawDate).add(-5, "hour"),
      } as any;
      if (data?.extraDraws?.length > 0) {
        if (rowsExtrasInit?.length) {
          const extraCopy = [...rowsExtrasInit];
          body["extraDraws"] = [...data?.extraDraws, ...extraCopy];
        } else {
          body["extraDraws"] = data?.extraDraws;
        }
      }
      if (rowsExtrasInit?.length) {
      }

      console.log("<<<", body);

      update(body);
    }
  };

  return (
    <>
      {id && (
        <Box display="flex" alignItems="center" mt={2}>
          <Typography margin="dense" fontWeight={600} mr={2}>
            Stock de números disponibles:
          </Typography>
          <Chip label={data?.stock} />
        </Box>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <label style={{ fontWeight: 600 }}>Título</label>
        <TextField
          sx={{ marginBottom: "16px" }}
          required
          disabled={id === undefined ? false : true}
          id="title"
          fullWidth
          size="small"
          margin="dense"
          {...register("title" as never)}
        />
        <label style={{ fontWeight: 600 }}>Descripción</label>
        <TextField
          sx={{ marginBottom: "16px" }}
          required
          disabled={id === undefined ? false : true}
          multiline
          rows={4}
          id="description"
          fullWidth
          size="small"
          margin="dense"
          {...register("description" as never)}
        />
        <label style={{ fontWeight: 600 }}>Imagen</label>
        <br />
        <input
          disabled={id === undefined ? false : true}
          style={{ marginBottom: "16px", marginTop: "16px" }}
          id="upload-photo"
          name="upload-photo"
          type="file"
          accept="image/*"
          onChange={(e) => handleSetImage(e)}
        />
        <br />
        <label style={{ fontWeight: 600 }}>Lotería</label>
        <TextField
          sx={{ marginBottom: "16px" }}
          required
          id="lottery"
          disabled={id === undefined ? false : true}
          fullWidth
          size="small"
          margin="dense"
          {...register("lottery" as never)}
        />
        <label style={{ fontWeight: 600 }}>Precio unitario</label>
        <TextField
          sx={{ marginBottom: "16px" }}
          required
          type="text"
          disabled={id === undefined ? false : true}
          id="unitPrice"
          fullWidth
          size="small"
          margin="dense"
          value={unitPrice}
          onChange={handleUnitPriceChange}
          //{...register("unitPrice" as never)}
        />
        <label style={{ fontWeight: 600 }}>Mínimo de boletas a comprar</label>
        <TextField
          sx={{ marginBottom: "16px" }}
          required
          type="number"
          id="minTickets"
          disabled={id === undefined ? false : true}
          fullWidth
          size="small"
          margin="dense"
          {...register("minTickets" as never)}
        />
        <label style={{ fontWeight: 600 }}>Fecha del sorteo</label>
        <DateTimePicker
          value={drawDate}
          disabled={moment().isBefore(data?.drawDate)}
          timezone="America/Bogota"
          minDateTime={moment()}
          onChange={(e) => setDrawDate(e)}
          sx={{ width: "100%", marginBottom: "16px" }}
        />

        {id && (
          <Box>
            <hr />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <label style={{ fontWeight: 600 }}>Información del ganador</label>

              <Box>
                <Tooltip title="Verificar ganador">
                  <IconButton
                    color="primary"
                    onClick={handleOpen}
                    disabled={
                      moment().isBefore(data?.drawDate) ||
                      moment().isSame(data?.drawDate) ||
                      data?.winnerName
                    }
                  >
                    <HowToRegIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography mr={2}>Numero ganador: </Typography>
              <Typography color="primary" fontWeight={600} fontSize={20}>
                {data?.winnerTicket}
              </Typography>
            </Box>
            {rows.length > 0 ? (
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          [`&.${tableCellClasses.head}`]: {
                            backgroundColor: "#1976D2",
                            color: "white",
                          },
                        }}
                      >
                        Nombre
                      </TableCell>
                      <TableCell
                        sx={{
                          [`&.${tableCellClasses.head}`]: {
                            backgroundColor: "#1976D2",
                            color: "white",
                          },
                        }}
                      >
                        Email
                      </TableCell>
                      <TableCell
                        sx={{
                          [`&.${tableCellClasses.head}`]: {
                            backgroundColor: "#1976D2",
                            color: "white",
                          },
                        }}
                      >
                        Celular
                      </TableCell>
                      <TableCell
                        sx={{
                          [`&.${tableCellClasses.head}`]: {
                            backgroundColor: "#1976D2",
                            color: "white",
                          },
                        }}
                      >
                        Departamento
                      </TableCell>
                      <TableCell
                        sx={{
                          [`&.${tableCellClasses.head}`]: {
                            backgroundColor: "#1976D2",
                            color: "white",
                          },
                        }}
                      >
                        Ciudad
                      </TableCell>
                      <TableCell
                        sx={{
                          [`&.${tableCellClasses.head}`]: {
                            backgroundColor: "#1976D2",
                            color: "white",
                          },
                        }}
                      >
                        Dirección
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.firstName}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.firstName}
                        </TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        <TableCell align="left">{row.cellPhone}</TableCell>
                        <TableCell align="left">{row.department}</TableCell>
                        <TableCell align="left">{row.city}</TableCell>
                        <TableCell align="left">{row.address}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Box>
                <Typography>
                  {<strong>Nombre:</strong>} {data?.winnerName}
                </Typography>
              </Box>
            )}
            <br />
          </Box>
        )}

        {id &&
          data?.winnerName &&
          data?.drawDate &&
          moment().isAfter(data?.drawDate) && (
            <Box display="flex" justifyContent="flex-start">
              <Button
                color="primary"
                onClick={() => append({ number: 0, gift: "" })}
                sx={{ fontWeight: 600, marginBottom: "16px" }}
              >
                + Verificar ganador otros números
              </Button>
            </Box>
          )}

        {fields.map((user, index) => (
          <>
            <hr />
            <Box
              key={user.id}
              sx={{
                backgroundColor: "ghostwhite",
                padding: "8px",
                borderRadius: "8px",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <label style={{ fontWeight: 600 }}>{`Premio de consolación ${
                  index + 1
                }`}</label>
                <Button
                  color="error"
                  onClick={() => remove(index)}
                  //disabled={id === undefined ? false : true}
                >
                  X Remover
                </Button>
              </Box>
              <Grid container spacing={4}>
                <Grid item md={4} xs={12}>
                  <label style={{ fontWeight: 600 }}>Número</label>
                  <Controller
                    name={`extraDraws[${index}].number` as never}
                    control={control}
                    defaultValue={0 as never}
                    render={({ field }) => (
                      <TextField
                        size="small"
                        margin="dense"
                        fullWidth
                        //disabled={id === undefined ? false : true}
                        error={
                          errors["extraDraws"] &&
                          !!errors["extraDraws"][index]?.number
                        }
                        helperText={
                          errors["extraDraws"] &&
                          (errors["extraDraws"][index]?.number
                            ?.message as string)
                        }
                        {...field}
                        inputProps={{
                          maxLength: 6,
                          onKeyDown: (e: any) => {
                            if (
                              e.target.value.length >= 6 &&
                              e.key !== "Backspace" &&
                              e.key !== "Delete" &&
                              e.key !== "ArrowLeft" &&
                              e.key !== "ArrowRight"
                            ) {
                              e.preventDefault();
                            }
                          },
                        }}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={8} xs={12}>
                  <label style={{ fontWeight: 600 }}>
                    Descripción del premio
                  </label>
                  <Controller
                    name={`extraDraws[${index}].gift` as never}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        size="small"
                        margin="dense"
                        //disabled={id === undefined ? false : true}
                        fullWidth
                        error={
                          errors["extraDraws"] &&
                          !!errors["extraDraws"][index]?.gift
                        }
                        helperText={
                          errors["extraDraws"] &&
                          (errors["extraDraws"][index]?.gift?.message as string)
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        ))}
        {rowsExtras.length > 0 && (
          <Box mb={2}>
            <hr />
            <label style={{ fontWeight: 600 }}>Premios de consolación</label>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        [`&.${tableCellClasses.head}`]: {
                          backgroundColor: "#1976D2",
                          color: "white",
                        },
                      }}
                    >
                      Número
                    </TableCell>
                    <TableCell
                      sx={{
                        [`&.${tableCellClasses.head}`]: {
                          backgroundColor: "#1976D2",
                          color: "white",
                        },
                      }}
                    >
                      Premio
                    </TableCell>
                    <TableCell
                      sx={{
                        [`&.${tableCellClasses.head}`]: {
                          backgroundColor: "#1976D2",
                          color: "white",
                        },
                      }}
                    >
                      Ganador
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsExtras.map((row) => (
                    <TableRow
                      key={row.number}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">{row.number}</TableCell>
                      <TableCell align="left">{row.gift}</TableCell>
                      <TableCell align="left">
                        <Box display="flex" flexDirection="column">
                          {row.order !== "" && (
                            <span>{`id compra: ${row.order}`}</span>
                          )}
                          <span>{row.firstName}</span>
                          <span>{row.email}</span>
                          <span>{row.cellPhone}</span>
                          <span>{row.department}</span>
                          <span>{row.city}</span>
                          <span>{row.address}</span>
                          {row.order !== "" && (
                            <Button
                              size="small"
                              onClick={() =>
                                window.open(
                                  `https://reydelossuenos.com.co/numeros/${row.order}`,
                                  "_blank"
                                )
                              }
                            >
                              Verificar número
                            </Button>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}

        {!id && (
          <Box display="flex" justifyContent="flex-start">
            <Button
              color="primary"
              onClick={() => append({ number: 0, gift: "" })}
              sx={{ fontWeight: 600, marginBottom: "16px" }}
            >
              + Agregar premios de consolación
            </Button>
          </Box>
        )}

        <Button
          type="submit"
          variant="contained"
          fullWidth
          //onClick={onSubmit}
          disabled={
            isLoading ||
            isLoadingUpdate ||
            (data?.drawDate && moment().isBefore(data?.drawDate))
          }
          sx={{ fontWeight: 600, marginBottom: "16px" }}
        >
          {id ? "Actualizar" : "Crear"}
        </Button>
        {errorMsg.type === "success" && (
          <Alert severity="success">{errorMsg.msg}</Alert>
        )}
        {errorMsg.type === "error" && (
          <Alert severity="error">{errorMsg.msg}</Alert>
        )}
      </form>
      {id && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
            >
              <strong>Verificar ganador</strong>
            </Typography>
            {stepper === "init" && (
              <Box>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Ingrese el número ganador de la lotería.
                </Typography>
                <FormControl sx={{ width: "100%", mt: 2 }} size="small">
                  <TextField
                    label="Número ganador"
                    id="outlined-size-small"
                    value={number}
                    onChange={onChangeNumber}
                    size="small"
                  />
                </FormControl>
                <Box display="flex" justifyContent="flex-end" mt={2}>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ mr: 2 }}
                    disabled={isLoadingWinner}
                    onClick={handleClose}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    disabled={isLoadingWinner || number.length < 6}
                    onClick={getWinner}
                  >
                    Verificar
                  </Button>
                </Box>
              </Box>
            )}
            {stepper === "assign" && (
              <Box>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  No se encontró un ganador, ¿Desea agregar un ganador?
                </Typography>
                <FormControl sx={{ width: "100%", mt: 2 }} size="small">
                  <TextField
                    label="Nombre del ganador"
                    id="outlined-size-small"
                    value={winner}
                    onChange={onChangeWinner}
                    size="small"
                  />
                </FormControl>
                <Box display="flex" justifyContent="flex-end" mt={2}>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ mr: 2 }}
                    disabled={isLoadingUpdate}
                    onClick={handleClose}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    disabled={winner.length < 1 || isLoadingUpdate}
                    onClick={() =>
                      update({ winnerName: winner, winnerTicket: number })
                    }
                  >
                    Asignar
                  </Button>
                </Box>
              </Box>
            )}
            {stepper === "winner" && (
              <Box>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  ¡Se ha encontrado un ganador! Cierre el modal para ver los
                  detalles.
                </Typography>
                <CheckCircleIcon sx={{ fontSize: 50 }} color="success" />
                <Box display="flex" mt={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ mr: 2 }}
                    onClick={handleClose}
                  >
                    Cerrar
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Modal>
      )}
    </>
  );
};

function createData(
  firstName: string,
  email: string,
  cellPhone: any,
  department: any,
  city: any,
  address: any
) {
  return { firstName, email, cellPhone, department, city, address };
}

function createDataExtra(
  number: any,
  gift: string,
  firstName: string,
  email: string,
  cellPhone: any,
  department: any,
  city: any,
  address: any,
  order: any
) {
  return {
    number,
    gift,
    firstName,
    email,
    cellPhone,
    department,
    city,
    address,
    order,
  };
}
