import { useLottery } from "@hooks";
import { LayoutTheme } from "@components";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Skeleton,
  styled,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import error from "../../assets/error.json";

export const Giveaway = () => {
  const navigate = useNavigate();
  const { data, isLoading, isError } = useLottery();

  return (
    <LayoutTheme titleOne="Sorteos" titleTwo="Activos">
      <Box display="flex" flexDirection="column" alignItems="center">
        {isLoading ? (
          Array.from([1, 2, 3]).map((i) => (
            <Skeleton
              variant="rectangular"
              width={345}
              height={246}
              sx={{ marginTop: "24px" }}
            />
          ))
        ) : (
          <>
            {isError ? (
              <Lottie animationData={error} />
            ) : (
              <div>
                {data && data.length === 0 ? (
                  <div>
                    <Typography
                      component="span"
                      variant="h3"
                      sx={{
                        color: (theme) =>
                          theme.palette.mode === "light"
                            ? "primary.main"
                            : "primary.light",
                      }}
                    >
                      No hay sorteos activos
                    </Typography>
                  </div>
                ) : (
                  data?.map((i: any) => (
                    <Cards navigate={navigate} giveaway={i} />
                  ))
                )}
              </div>
            )}
          </>
        )}
      </Box>
    </LayoutTheme>
  );
};

const Ribbon = styled('div')({
  width: '160px',
  height: '160px',
  overflow: 'hidden',
  position: 'absolute',
  top: '-10px',
  right: '-10px',
  zIndex: 1,
  '& span': {
    top: '50px',
    left: '-32px',
    position: 'absolute',
    display: 'block',
    width: '240px',
    padding: '12px 0',
    backgroundColor: '#ff0000',
    color: '#fff',
    fontWeight: 600,
    fontSize: '15px',
    textAlign: 'center',
    transform: 'rotate(45deg)',
    boxShadow: '0 3px 10px -5px rgba(0, 0, 0, 1)',
    animation: 'bounce 1s infinite'
  },
  '@keyframes bounce': {
    '0%, 100%': {
      transform: 'rotate(45deg) translateY(0)'
    },
    '50%': {
      transform: 'rotate(45deg) translateY(-10px)'
    }
  }
});

const Cards = ({ navigate, giveaway }) => {
  return (
    <Card
      sx={{
        width: { xs: 280, md: 345 },
        marginTop: '24px',
        outlineColor: 'rgba(156, 204, 252, 0.1)',
        boxShadow: '0px 0px 8px 0px rgba(3, 51, 99)',
        position: 'relative' // Necesario para que la cinta se posicione correctamente
      }}
    >
      <Ribbon>
        <span>&#8595;&#8595; COMPRA AQUÍ &#8595;&#8595;</span>
      </Ribbon>
      <CardActionArea
        onClick={() => navigate(`/sorteos-activos/${giveaway?.id}`)}
      >
        <Box
          sx={{
            width: '100%',
            height: '400px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CardMedia
            component="img"
            sx={{
              maxWidth: '100%',
              maxHeight: '100%',
              height: 'auto',
              width: 'auto',
            }}
            image={giveaway?.imageURL}
            alt="green iguana"
          />
        </Box>
        <CardContent>
          <Box mb={1}>
            <Typography gutterBottom variant="h5" component="div">
              {giveaway?.name}
            </Typography>
            <Chip
              label="Activo ¡CLIC AQUÍ!"
              color="success"
              sx={{
                '@keyframes pulsate': {
                  from: {
                    opacity: 1,
                    transform: 'scale(1)',
                  },
                  to: {
                    opacity: 0,
                    transform: 'scale(1)',
                  },
                },
                animation: 'pulsate 2s infinite ease',
              }}
            />
          </Box>
          <Typography variant="body2" color="text.secondary">
            {giveaway?.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

