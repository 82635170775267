import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { ReactElement } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { LayoutAdmin } from "@components";
import {
  Lottery,
  Home,
  Giveaway,
  GiveawayConcluded,
  GiveawayDetail,
  Buy,
  AddGiveaway,
  GiveawayDetailConcluded,
  Orders,
  AddOrder,
  ViewNumbers,
  ViewNumbersMP,
  MyNumbers,
  Checkout
} from "@pages";
import getLPTheme from "./themes/custom-theme";
import { SignIn } from "./pages/SignIn/SignIn";
import { defaultTheme } from "@themes";

export default (): ReactElement => {
  console.log("ENVIRONMENT: ", process.env.REACT_APP_ENVIRONMENT);

  const location = useLocation();
  const LPtheme = createTheme(getLPTheme("dark"));
  const theme =
    location?.pathname.includes("admin") ||
    location?.pathname.includes("numeros") ||
    location?.pathname.includes("numero-mercado-pago")
      ? defaultTheme
      : LPtheme;
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route>
          <Route path="/admin/iniciar-sesion" element={<SignIn />} />
          <Route element={<LayoutAdmin />}>
            <Route path="/admin" element={<Lottery />} />
            <Route path="/admin/compras" element={<Orders />} />
            <Route path="/admin/crear-sorteo" element={<AddGiveaway />} />
            <Route path="/admin/sorteo/:id" element={<AddGiveaway />} />
            <Route path="/admin/crear-compra" element={<AddOrder />} />
          </Route>
          <Route path="/" element={<Home />}>
            <Route path="/" element={<Giveaway />} />
            <Route path="/sorteos-concluidos" element={<GiveawayConcluded />} />
            <Route path="/sorteos-activos/:id" element={<GiveawayDetail />} />
            {/* <Route path="/sorteos-activos/:id/comprar" element={<Buy />} /> */}
            <Route path="/sorteos-activos/:id/comprar" element={<Checkout />} />
            <Route
              path="/sorteos-concluidos/:id"
              element={<GiveawayDetailConcluded />}
            />
            <Route path="/numeros/:id" element={<ViewNumbers />} />
            <Route path="/numero-mercado-pago" element={<ViewNumbersMP />} />
            <Route path="/mis-numeros" element={<MyNumbers />} />
          </Route>
        </Route>
      </Routes>
    </ThemeProvider>
  );
};
