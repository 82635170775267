import {
  alpha,
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

export const LayoutTheme = ({ children, titleOne, titleTwo }: any) => {
  const navigate = useNavigate();
  const showMyNumbers =
    (titleTwo === "Activos" && titleOne === "Sorteos") ||
    titleOne === "Detalle del" && titleTwo === "Sorteo";
  const [cellphone, setCellphone] = useState("");

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setCellphone(value);
    }
  };

  const handleSearch = () => {
    navigate(`/mis-numeros?cellphone=${cellphone}`);
  };

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        backgroundImage:
          theme.palette.mode === "light"
            ? "linear-gradient(180deg, #CEE5FD, #FFF)"
            : "linear-gradient(#02294F, #090E10)",
        backgroundSize: "100% 20%",
        backgroundRepeat: "no-repeat",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: "100%", sm: "70%" } }}>
          <Typography
            component="h3"
            variant="h3"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignSelf: "center",
              textAlign: "center",
            }}
          >
            {titleOne}&nbsp;
            <Typography
              component="span"
              variant="h3"
              sx={{
                color: (theme) =>
                  theme.palette.mode === "light"
                    ? "primary.main"
                    : "primary.light",
              }}
            >
              {titleTwo}
            </Typography>
          </Typography>
        </Stack>
        {showMyNumbers && (
          <Box mt={3} padding={2}>
            <Box textAlign="center">
              <label style={{ fontSize: "14px", fontWeight: 600 }}>
                Consulta tus números
              </label>
            </Box>

            <Box display="flex" alignItems="center" mt={1}>
              <TextField
                placeholder="Celular"
                value={cellphone}
                onChange={handleInputChange}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    width: "150px !important",
                    minWidth: 0,
                  },
                }}
              />
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleSearch}
                disabled={cellphone.length !== 10}
                sx={{ marginLeft: 2 }}
              >
                Buscar
              </Button>
            </Box>
          </Box>
        )}
        <Box
          id="image"
          sx={(theme) => ({
            padding: "20px",
            mt: { xs: 8, sm: 10 },
            alignSelf: "center",
            //height: { xs: "160vh", sm:"100%", md: "100%", lg: "100%" },
            height: "100%",
            //height: "100%",
            width: "100%",
            backgroundImage:
              theme.palette.mode === "light"
                ? 'url("/static/images/templates/templates-images/hero-light.png")'
                : 'url("/static/images/templates/templates-images/hero-dark.png")',
            backgroundSize: "cover",
            borderRadius: "10px",
            outline: "1px solid",
            outlineColor:
              theme.palette.mode === "light"
                ? alpha("#BFCCD9", 0.5)
                : alpha("#9CCCFC", 0.1),
            boxShadow:
              theme.palette.mode === "light"
                ? `0 0 12px 8px ${alpha("#9CCCFC", 0.2)}`
                : `0 0 24px 12px ${alpha("#033363", 0.2)}`,
          })}
        >
          {children}
        </Box>
      </Container>
    </Box>
  );
};
