import axios, {
  AxiosInstance,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { environment, getData } from "../utils";
export class Http {
  readonly instance;

  constructor(url: string = process.env.REACT_API_URL) {
    this.instance = axios.create({
      baseURL: url,
      timeout: 40000,
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
      },
    });

    this.addBearToken();
    this.handleResponse();
  }

  getInstance(): AxiosInstance {
    return this.instance;
  }

  private addBearToken(): void {
    this.instance.interceptors.request.use(
      async (
        config: InternalAxiosRequestConfig
      ): Promise<InternalAxiosRequestConfig> => {
        const token = await getData("token");
        if (token) {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`,
          } as AxiosRequestHeaders;
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }

  private handleResponse(): void {
    this.instance.interceptors.response.use(
      (response: AxiosResponse) => {
        return Promise.resolve(response.data);
      },
      function (error) {
        return Promise.reject(error.response);
      }
    );
  }
}
