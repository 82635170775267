import { LayoutTheme } from "@components";
import { useLotteryFinished } from "@hooks";
import {
  alpha,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Link,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import error from "../../assets/error.json";

export const GiveawayConcluded = () => {
  const navigate = useNavigate();
  const { data, isLoading, isError } = useLotteryFinished();

  return (
    <LayoutTheme titleOne="Sorteos" titleTwo="Concluidos">
      <Box display="flex" flexDirection="column" alignItems="center">
        {isLoading ? (
          Array.from([1, 2, 3]).map((i) => (
            <Skeleton
              variant="rectangular"
              width={345}
              height={246}
              sx={{ marginTop: "24px" }}
            />
          ))
        ) : (
          <>
            {isError ? (
              <Lottie animationData={error} />
            ) : (
              <div>
                {data && data.length === 0 ? (
                  <div>
                    <Typography
                      component="span"
                      variant="h3"
                      sx={{
                        color: (theme) =>
                          theme.palette.mode === "light"
                            ? "primary.main"
                            : "primary.light",
                      }}
                    >
                      No hay sorteos concluidos
                    </Typography>
                  </div>
                ) : (
                  data?.map((i: any) => (
                    <Cards navigate={navigate} giveaway={i} />
                  ))
                )}
              </div>
            )}
          </>
        )}
      </Box>
    </LayoutTheme>
  );
};

const Cards = ({ navigate, giveaway }: any) => {
  return (
    <Card
      sx={{
        width: 345,
        marginTop: "24px",
        outlineColor: "rgba(156, 204, 252, 0.1)",
        boxShadow: "0px 0px 8px 0px rgba(3, 51, 99)",
      }}
    >
      <CardActionArea
        onClick={() => navigate(`/sorteos-concluidos/${giveaway?.id}`)}
      >
        <Box
          sx={{
            width: "100%",
            height: "400px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardMedia
            component="img"
            sx={{
              maxWidth: "100%",
              maxHeight: "100%",
              height: "auto",
              width: "auto",
            }}
            image={giveaway?.imageURL}
            alt="green iguana"
          />
        </Box>
        <CardContent>
          <Box mb={1}>
            <Typography gutterBottom variant="h5" component="div">
              {giveaway?.name}
            </Typography>
            <Chip label="Concluido" disabled />
          </Box>

          <Typography variant="body2" color="text.secondary">
            {giveaway?.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
