import { LotteryService } from "@services";
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "react-query";

export const useLottery = (): UseQueryResult<any> => {
  return useQuery(["lottery-list"], () => LotteryService.getAll(), {
    refetchOnWindowFocus: false,
    retry: false,
  });
};

export const useLotteryFinished = (): UseQueryResult<any> => {
  return useQuery(["lottery-list-finished"], () => LotteryService.getAllFinished(), {
    refetchOnWindowFocus: false,
    retry: false,
  });
};

export const useLotteryAdmin = (): UseQueryResult<any> => {
  return useQuery(["lottery-list-admin"], () => LotteryService.getAllAdmin(), {
    refetchOnWindowFocus: false,
    retry: false,
  });
};

export const useOrdersAdmin = (): UseQueryResult<any> => {
  return useQuery(["orders-list-admin"], () => LotteryService.getOrders(), {
    refetchOnWindowFocus: false,
    retry: false,
  });
};

export const useGetDetail = (request: any): UseQueryResult<any> => {
  return useQuery(
    ["lottery-detail", request],
    (id: any) => LotteryService.getDetail(request),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};

export const useGetDetailAdmin = (request: any): UseQueryResult<any> => {
  return useQuery(
    ["lottery-detail-admin", request],
    (id: any) => LotteryService.getDetailAdmin(request),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: request.id ? true: false
    }
  );
};

export const useGetDetailConcluded = (request: any): UseQueryResult<any> => {
  return useQuery(
    ["lottery-detail-finished", request],
    (id: any) => LotteryService.getDetailFinished(request),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};

export const useGetUserByCellphone = (request: any): UseQueryResult<any> => {
  return useQuery(
    ["get-user-by-cellphone", request],
    (id: any) => LotteryService.getUserByCellphone(request),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: request.cellphone.length === 10,
    }
  );
};

export const useCreateLottery = (): UseMutationResult<any> => {
  return useMutation({
    mutationKey: ["create-lottery"],
    mutationFn: (request: any) => LotteryService.createLottery(request),
    retry: false,
  });
};

export const useUpdateLottery = (id): UseMutationResult<any> => {
  return useMutation({
    mutationKey: ["update-lottery"],
    mutationFn: (request: any) => LotteryService.updateLottery(request, id),
    retry: false,
  });
};

export const useUpdateStatusOrder = (id): UseMutationResult<any> => {
  return useMutation({
    mutationKey: ["update-order"],
    mutationFn: (request: any) => LotteryService.updateOrder(request, id),
    retry: false,
  });
};

export const useGetWinner = (request: any): UseQueryResult<any> => {
  return useQuery(
    ["get-winner", request],
    () => LotteryService.getWinner(request),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: request.number === 6,
    }
  );
};

export const useGetPurchases = (request: any): UseQueryResult<any> => {
  return useQuery(
    ["get-purchases", request],
    () => LotteryService.getPurchases(request),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: request.cellphoneUrl !== null || request.search
    }
  );
};

export const usePublishWS = (): UseMutationResult<any> => {
  return useMutation({
    mutationKey: ["publish-sw"],
    mutationFn: (request: any) => LotteryService.publishWS(request),
    retry: false,
  });
};

export const useGetNumbers = (request: any): UseQueryResult<any> => {
  return useQuery(
    ["get-numbers", request],
    (id: any) => LotteryService.getNumbers(request),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};

export const useGetNumbersMP = (request: any): UseQueryResult<any> => {
  return useQuery(
    ["get-numbers-mp", request],
    (id: any) => LotteryService.getNumbersMP(request),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};