import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

export const useFormSubmit = (validationSchema: any) => {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        setValue
      } = useForm({
        resolver: yupResolver(validationSchema, undefined, undefined),
      });

      return {register, handleSubmit, control, errors, setValue};
}