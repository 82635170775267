import { TLotteryItem } from "@types";
import { Http } from "../libs/http";

class OrderServices {
  private http;

  // constructor() {
  //   this.http = new Http().getInstance();
  // }

  createOrder(body: any): Promise<any> {
    this.http = new Http().getInstance();
    return this.http.post("/buy", body);
  }

  createPreference(body: any): Promise<any> {
    this.http = new Http().getInstance();
    return this.http.post("/buy-mercadopago", body);
  }

  signIn(body: any): Promise<any> {
    this.http = new Http().getInstance();
    return this.http.post("/signin", body);
  }

  reset(body: any): Promise<any> {
    this.http = new Http().getInstance();
    return this.http.put("/reset", body);
  }
}

export const OrderService = new OrderServices();
