import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  alpha,
  Box,
  Button,
  Chip,
  Grid,
  InputBase,
  MenuItem,
  Paper,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { CounterInput, GiveawayForm, UserForm } from "@components";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreateOrder,
  useFormSubmit,
  useGetUserByCellphone,
  useLottery,
  useUpdateStatusOrder,
} from "@hooks";
import * as Yup from "yup";
import axios from "axios";
import Ably from "ably";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const AddOrder = () => {
  const navigate = useNavigate();
  const [cellphone, setCellphone] = useState("");
  const [stock, setStock] = useState(1);
  const [unitPrice, setUnitPrice] = useState(1);
  const [minTickets, seMinTickets] = useState(1);
  const [lotterySelected, setLotterySelected] = useState<any>(null);
  const [lotteries, setLotteries] = useState<any>(null);
  const [count, setCount] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");

  const { data, isLoading, isError, isSuccess } = useLottery();
  const {
    mutate,
    isSuccess: isSuccessMutate,
    isLoading: isLoadingMutate,
    isError: isErrorMutate,
    error: fieldErrors,
    data: dataMutate,
  } = useCreateOrder();

  // const {
  //   mutate: update,
  //   isError: isErrorUpdate,
  //   isLoading: isLoadingUpdate,
  //   isSuccess: isSuccessUpdate,
  // } = useUpdateStatusOrder(dataMutate?.order?.id);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Nombre es obligatorio"),
    lastName: Yup.string().required("Apellido es obligatorio"),
    //document: Yup.string().required("Número de identificación es obligatorio"),
    email: Yup.string().required("Email es obligatorio"),
    department: Yup.object().required("Departamento es obligatorio"),
    city: Yup.string().required("Ciudad es obligatoria"),
    address: Yup.string().required("Dirección es obligatoria"),
    acceptTermsAndConditions: Yup.bool().oneOf(
      [true],
      "Acepte los Términos y Condiciones"
    ),
  });

  const { register, handleSubmit, control, errors, setValue } =
    useFormSubmit(validationSchema);

  const {
    data: dataUser,
    isLoading: isLoadingUser,
    isError: isErrorUser,
    isFetching,
    isSuccess: isSuccessUser,
  } = useGetUserByCellphone({
    cellphone,
  });

  const onSubmit = (data: any) => {
    const payload = { ...data };
    payload.cellPhone = cellphone;
    payload.department = payload.department.name_dep;
    payload.order = {
      quantity: count,
      unitPrice: unitPrice,
      paymentMethod: "efectivo",
    };
    payload.lotteryId = lotterySelected?.id;
    mutate(payload);
  };

  useEffect(() => {
    if (isSuccessMutate) {
      navigate(`/admin/compras`, {
        state: {
          showMsg: true,
          msg: "La compra se ha creado con éxito.",
        },
      });
    }
  }, [isLoadingMutate]);

  useEffect(() => {
    if (isSuccess) {
      setLotteries(data);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isSuccessUser) {
      if (dataUser) {
        setValue("firstName", dataUser?.firstName);
        setValue("lastName", dataUser?.lastName);
      }
    }
  }, [isFetching]);

  useEffect(() => {
    if (lotterySelected?.id) {
      axios
        .post(
          process.env.WS_URL,
          { message: lotterySelected?.id },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers":
                "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
            },
          }
        )
        .then((r) => {
          setStock(r.data?.stock);
          setUnitPrice(r.data?.pricePerTicket);
          seMinTickets(r.data?.minQuantityTickets);
          setCount(r.data?.minQuantityTickets);
        })
        .catch((e) => console.log(e));
    }
    //setMessage("");
  }, [lotterySelected]);

  useEffect(() => {
    if (lotterySelected?.id) {
      const ably = new Ably.Realtime(process.env.ABLY_API_KEY);

      // Replace 'example_channel' with your desired channel name
      const channel = ably.channels.get(lotterySelected?.id);

      // Listen for messages on the channel
      channel.subscribe("example_event", (message) => {
        setStock(message.data?.stock);
      });

      return () => {
        // Cleanup subscriptions when the component unmounts
        channel.unsubscribe();
        //ably.close();
      };
    }
  }, [lotterySelected]);

  return (
    <Box padding={3}>
      <Fragment>
        <Paper>
          <Box padding={2}>
            <Button variant="text" onClick={() => navigate("/admin/compras")}>
              Atras
            </Button>
          </Box>
          <Box padding={3}>
            <Typography
              variant="h6"
              align="center"
              margin="dense"
              fontWeight={600}
            >
              Formulario creación de Orden de Compra
            </Typography>
            {lotteries?.length > 0 && (
              <>
                <label>Seleccione el sorteo activo</label>
                <TextField
                  required
                  select
                  size="small"
                  //id="department"
                  fullWidth
                  margin="dense"
                  onChange={(e: any) => setLotterySelected(e.target.value)}
                >
                  {lotteries.map((option: any) => (
                    <MenuItem key={option.id} value={option}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </>
            )}
            {lotterySelected && (
              <>
                <Box display="flex" alignItems="center" mt={2}>
                  <Typography margin="dense" fontWeight={600} mr={2}>
                    Stock de números disponibles:
                  </Typography>
                  <Chip label={stock} />
                </Box>
                <Box display="flex" alignItems="center" mt={2} mb={2}>
                  <Typography margin="dense" fontWeight={600} mr={2}>
                    Precio unitario:
                  </Typography>
                  <Chip label={`$${unitPrice?.toLocaleString()}`} />
                </Box>
                <hr />
                <Grid container mb={2}>
                  <Grid item xs={6} textAlign="center">
                    <label>Números a comprar</label>
                    <CounterInput
                      setCount={setCount}
                      min={minTickets}
                      count={count}
                      stock={stock}
                      setErrorMsg={setErrorMsg}
                      errorMsg={errorMsg}
                    />
                  </Grid>
                  <Grid item xs={6} textAlign="center">
                    <p style={{ margin: 0 }}>Valor total</p>
                    <BootstrapInput
                      disabled
                      value={`$${(count * unitPrice).toLocaleString()}`}
                      id="bootstrap-input"
                    />
                  </Grid>
                </Grid>
                <hr />
                <Accordion sx={{ mt: 3 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <strong>Formulario de información personal</strong>
                  </AccordionSummary>
                  <AccordionDetails>
                    <UserForm
                      register={register}
                      control={control}
                      errors={errors}
                      isLoading={isSuccessUser}
                      data={dataUser}
                      cellphone={cellphone}
                      setCellphone={setCellphone}
                      handleSubmit={handleSubmit}
                      title=""
                      setValue={setValue}
                      origin={{
                        name: "customer",
                        submit: onSubmit,
                        isLoad: isLoadingMutate
                      }}
                      errorMsg={errorMsg}
                    />
                  </AccordionDetails>
                </Accordion>
                <br />
                {isErrorMutate && (
                  <Alert severity="error">
                    Ha ocurrido un error, inténtelo de nuevo.
                  </Alert>
                )}
              </>
            )}
          </Box>
        </Paper>
      </Fragment>
    </Box>
  );
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
