import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useState } from "react";
import { Alert, IconButton, Snackbar, SnackbarOrigin } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../../assets/img/rs.png";
import { useSignIn } from "@hooks";
import { useReset } from '../../hooks/queries/order';

const logoStyle = {
  width: "140px",
  height: "auto",
  cursor: "pointer",
};

interface State extends SnackbarOrigin {
  open: boolean;
}

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="/">
        Rey de los sueños
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export const SignIn = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [fail, setFail] = useState(false);
  const [msgReset, setMsgReset] = useState(null);
  const [state, setState] = useState<State>({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const navigate = useNavigate();
  const { mutate, data, isLoading, isError, isSuccess } = useSignIn();
  const {
    mutate: reset,
    data: dataReset,
    isLoading: isLoadingReset,
    isError: isErrorReset,
    isSuccess: isSuccessReset,
  } = useReset();

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setState({ ...state, open: false });
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (!showAlert) {
      const payload = {
        email: data.get("email"),
        password: data.get("password"),
      };
      mutate(payload);
    } else {
      const payload = {
        email: data.get("email"),
      };
      reset(payload);
    }
  };

  React.useEffect(() => {
    setFail(false);
    if (isSuccess) {
      localStorage.setItem("tokenTicket", data.token);
      navigate("/admin");
    }
    if (isError) {
      setFail(true);
    }
  }, [isLoading]);

  React.useEffect(() => {
    setFail(false);
    setShowAlert(false);
    if (isSuccessReset) {
      setMsgReset({
        msg: "La nueva contraseña ha sido enviada con exito",
        success: true,
      });
    }
    if (isErrorReset) {
      setMsgReset({
        msg: "Ha ocurrido un problema, intente de nuevo",
        success: false,
      });
    }
  }, [isLoadingReset]);

  const handleReset = () => {
    setFail(false);
    setShowAlert(!showAlert);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {open && (
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            action={action}
            anchorOrigin={{ vertical, horizontal }}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              variant="filled"
              sx={{ width: "100%" }}
            >
              Correo o contraseña incorrectas
            </Alert>
          </Snackbar>
        )}
        {fail && <Alert severity="error">Autenticación fallida</Alert>}
        {msgReset && (
          <Alert severity={msgReset.success ? "success" : "error"}>
            {msgReset.msg}
          </Alert>
        )}
        {showAlert && (
          <Alert severity="info">
            La información para recuperar la contraseña será enviada al email
            proporcionado en el formulario. Si no llega en los próximos 5
            minutos reintente de nuevo.
          </Alert>
        )}
        <img src={logo} style={logoStyle} alt="logo of rs" />
        <Typography component="h1" variant="h5">
          {!showAlert ? "Iniciar sesion" : "Recuperar contraseña"}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Correo electrónico"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            disabled={showAlert}
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            disabled={isLoading || isLoadingReset}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {!showAlert ? "Iniciar sesion" : "Recuperar contraseña"}
          </Button>
          <Grid container>
            <Grid item xs>
              <span
                onClick={handleReset}
                style={{
                  color: "#1976d2",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {!showAlert ? "Olvido la contraseña?" : "Iniciar sesion"}
              </span>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};
