import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from "material-react-table";
import {
  Alert,
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  useLottery,
  useLotteryAdmin,
  useOrdersAdmin,
  useUpdateStatusOrder,
} from "@hooks";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import "moment/locale/es";
import EditIcon from "@mui/icons-material/Edit";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const Orders = () => {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState<any>({ type: "", msg: "" });
  const { data, isLoading, isError, refetch, isRefetching } = useOrdersAdmin();
  const location = useLocation();
  const [status, setStatus] = useState("");
  const alert = location?.state?.state;

  window.history.replaceState({}, "");

  const handleChangeStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [id, setId] = useState(null);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [open, setOpen] = useState(false);

  const {
    mutate: update,
    isError: isErrorUpdate,
    isLoading: isLoadingUpdate,
    isSuccess: isSuccessUpdate,
  } = useUpdateStatusOrder(id);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const handleOpen = (id: any) => {
    setOpen(true);
    setId(id);
  };
  const handleClose = () => setOpen(false);

  const updateStatus = () => update({ status });

  useEffect(() => {
    if (location.state) {
      setErrorMsg({
        type: "success",
        msg: "La compra se creo correctamente",
      });
    }
  }, []);

  const handleSendNumbers = (id: any, cellphone: any, paymentMethod: any) => {
    const msgWhatsApp = `*Muchas gracias por tu compra*
    En el siguiente enlace podrás ver tus números asignados: ${process.env.VIEW_NUMBERS}/${id}`;

    const encodedString = msgWhatsApp
      .split("\n")
      .map(encodeURIComponent)
      .join("%0A");
    const urlWhatsApp = `https://api.whatsapp.com/send?phone=+57${cellphone}&text=${encodedString}`;
    window.open(urlWhatsApp, "_blank")
  };

  useEffect(() => {
    if (isSuccessUpdate) {
      setErrorMsg({
        type: "success",
        msg: "La compra se actualizó correctamente",
      });
      refetch();
      handleClose();
    }
    if (isErrorUpdate) {
      setErrorMsg({
        type: "error",
        msg: "Ha ocurrido un error, intenta de nuevo",
      });
      handleClose();
    }
  }, [isLoadingUpdate]);

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    //column definitions...
    () => [
      {
        // accessorKey: "customer",
        header: "Acciones",
        size: 100,
        Cell: ({ renderedCellValue, row }) => (
          <Box display="flex" alignItems="center">
            <Tooltip title="Actualizar estado de compra">
              <IconButton
                disabled={
                  row.original.status === "rejected" ||
                  row.original.status === "approved"
                }
                onClick={() => handleOpen(row.original.id)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Reenviar números asignados">
              <IconButton
                onClick={() =>
                  handleSendNumbers(
                    row.original.id,
                    row.original.customer?.cellPhone,
                    row.original.paymentMethod
                  )
                }
                disabled={row.original.status !== "approved"}
              >
                <WhatsAppIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
      {
        accessorKey: "id",
        header: "ID de compra",
        size: 220,
      },
      {
        accessorKey: "status",
        header: "Estado",
        size: 150,
        Cell: ({ renderedCellValue, row }) => {
          const color =
            renderedCellValue === "pending"
              ? "warning"
              : renderedCellValue === "rejected"
              ? "error"
              : "success";
          return <Chip color={color} size="small" label={renderedCellValue} />;
        },
      },
      {
        accessorKey: "createdAt",
        header: "Fecha",
        size: 220,
        Cell: ({ cell }) => (
          <span>
            {moment(cell.getValue() as string)
              .locale("es")
              .format("DD/MMMM/YYYY - h:mm a")}
          </span>
        ),
      },
      {
        accessorKey: "paymentMethod",
        header: "Método de pago",
        size: 220,
      },
      {
        accessorKey: "customer.firstName",
        header: "Nombre",
        size: 150,
        Cell: ({ renderedCellValue, row }) => (
          <span>
            {row.original.customer.firstName}
          </span>
        ),
      },
      {
        accessorKey: "customer.lastName",
        header: "Apellido",
        size: 150,
        Cell: ({ renderedCellValue, row }) => (
          <span>
            {row.original.customer.lastName}
          </span>
        ),
      },
      {
        accessorKey: "customer.city",
        header: "Ciudad",
        size: 150,
        Cell: ({ renderedCellValue, row }) => (
          <span>
            {row.original.customer.city}
          </span>
        ),
      },
      {
        accessorKey: "customer.department",
        header: "Departamento",
        size: 150,
        Cell: ({ renderedCellValue, row }) => (
          <span>
            {row.original.customer.department}
          </span>
        ),
      },
      {
        accessorKey: "customer.cellPhone",
        header: "Celular",
        size: 150,
        Cell: ({ renderedCellValue, row }) => (
          <span>{row.original.customer?.cellPhone}</span>
        ),
      },
      {
        accessorKey: "quantity",
        header: "Boletas compradas",
        size: 150,
      },
      {
        // accessorKey: "customer",
        header: "Valor total",
        size: 180,
        Cell: ({ renderedCellValue, row }) => (
          <span>
            {`$${(
              row.original.unitPrice * row.original.quantity
            ).toLocaleString()}`}
          </span>
        ),
      },
      {
        accessorKey: "lottery.name",
        header: "Nombre sorteo",
        size: 220,
        Cell: ({ renderedCellValue, row }) => (
          <span>{`${row.original.lottery.name}`}</span>
        ),
      },
    ],
    []
    //end
  );

  const table = useMaterialReactTable({
    columns,
    data: data || [],
    initialState: { showColumnFilters: false },
    manualFiltering: false,
    manualPagination: false, //turn off built-in client-side pagination
    manualSorting: false, //turn off built-in client-side sorting
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    renderTopToolbarCustomActions: () => (
      <Tooltip arrow title="Refresh Data">
        <IconButton onClick={() => refetch()}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    ),
    //rowCount: dataLot?.length ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return (
    <Box padding={3} width="inherit">
      <Box display="flex" justifyContent="space-between" mb={3}>
        <Typography gutterBottom variant="h5" component="div" fontWeight={600}>
          Listado de compras
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddCircleOutlineIcon />}
          sx={{ fontWeight: 600 }}
          onClick={() => navigate(`/admin/crear-compra`)}
        >
          Agregar compra
        </Button>
      </Box>
      <Box my={2}>
        {errorMsg.type === "success" && (
          <Alert severity="success">{errorMsg.msg}</Alert>
        )}
        {errorMsg.type === "error" && (
          <Alert severity="error">{errorMsg.msg}</Alert>
        )}
      </Box>
      <MaterialReactTable table={table} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textAlign="center"
          >
            <strong>Modificación estado de compra</strong>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {`Seleccione el nuevo estado para la compra con id: ${id}`}
          </Typography>
          <FormControl sx={{ width: "100%", mt: 2 }} size="small">
            <InputLabel id="demo-select-small-label">Estado</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={status}
              label="Estado"
              onChange={handleChangeStatus}
            >
              <MenuItem value={"rejected"}>Rechazado</MenuItem>
              <MenuItem value={"approved"}>Aprobado</MenuItem>
            </Select>
          </FormControl>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              variant="contained"
              color="error"
              sx={{ mr: 2 }}
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              disabled={isLoadingUpdate}
              onClick={updateStatus}
            >
              Actualizar
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
