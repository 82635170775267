import { LayoutTheme } from "@components";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetDetailConcluded } from "../../hooks/queries/lottery";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DateTimeField } from "@mui/x-date-pickers";
import moment from "moment-timezone";

export const GiveawayDetailConcluded = () => {
  const { id } = useParams();
  const { data, isLoading, isError, isSuccess } = useGetDetailConcluded({
    id,
  });
  return (
    <LayoutTheme titleOne="Detalle del" titleTwo="Sorteo Concluido">
      <Box display="flex" flexDirection="column" alignItems="center">
        {isLoading && !isSuccess ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              height: "200px",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Card
            sx={{
              width: "100%",
              padding: "16px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "400px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardMedia
                component="img"
                sx={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  height: "auto",
                  width: "auto",
                }}
                image={data?.imageURL}
                alt="green iguana"
              />
            </Box>
            <CardContent>
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="space-between"
                sx={{ flexDirection: "column" }}
              >
                <Typography gutterBottom variant="h5" component="div">
                  {data?.name}
                </Typography>
                <Typography gutterBottom variant="h5" component="div">
                  {data?.nameLottery}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    flexDirection: { xs: "column", md: "row" },
                  }}
                  mt={2}
                  mb={2}
                >
                  <Box display="flex" alignItems="center">
                    <CalendarMonthIcon sx={{ mr: 1, my: 0.5 }} />
                    <Typography variant="h6" color="text.secondary">
                      <strong>
                        {moment(data.drawDate)
                          .add(5, "hour")
                          .format("DD/MMMM/YYYY - h:mm a")}
                      </strong>
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {data?.winnerName && (
                <>
                  <Typography variant="h6" color="text.secondary">
                    <strong>Nombre del Ganador</strong>
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    {data?.winnerName}
                  </Typography>
                </>
              )}
              <hr />
              <Box
                padding={2}
                textAlign="center"
                display="flex"
                flexDirection="column"
              >
                <Button variant="outlined">
                  <h1>{data?.winnerTicket}</h1>
                </Button>
                <span>Número ganador</span>
              </Box>

              <hr />
            </CardContent>
          </Card>
        )}
      </Box>
    </LayoutTheme>
  );
};
