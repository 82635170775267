import { Box, Button, Paper, Typography } from "@mui/material";
import { Fragment } from "react";
import { GiveawayForm } from "@components";
import { useNavigate, useParams } from "react-router-dom";

export const AddGiveaway = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <Box padding={3}>
      <Fragment>
        <Paper>
          <Box padding={2}>
            <Button variant="text" onClick={() => navigate("/admin")}>
              Atras
            </Button>
          </Box>
          <Box padding={3}>
            <Typography
              variant="h6"
              align="center"
              margin="dense"
              fontWeight={600}
            >
              {!id ? 'Formulario creación de sorteo' : 'Detalle del sorteo'}
            </Typography>
            <GiveawayForm />
          </Box>
        </Paper>
      </Fragment>
    </Box>
  );
};
