import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  Radio,
  CircularProgress,
  Backdrop,
  Alert,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  Bancolombia,
  Daviplata,
  LayoutTheme,
  MercadoPago,
  Nequi,
  UserForm,
} from "@components";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useCreateOrder,
  useCreatePreference,
  useFormSubmit,
  useGetUserByCellphone,
  usePublishWS,
} from "@hooks";
import * as Yup from "yup";
import Ably from "ably";

export const Checkout = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [stock, setStock] = useState(null);
  const [load, setLoad] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [preferenceId, setPreferenceId] = React.useState(null);
  const [payload, setPayload] = useState<any>({});
  const [cellphone, setCellphone] = useState("");
  const [idOrder, setIdOrder] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const count = location?.state?.count;
  const total = location?.state?.total;
  const name = location?.state?.name;
  const pricePerTicket = location?.state?.pricePerTicket;
  const msgWhatsApp = `¡Hola! este es el resumen de mi pedido:
  *ID Pedido:* ${idOrder}
  *Método de pago:* ${selectedValue}
  *Valor total:* $${total?.toLocaleString()}`;

  const encodedString = msgWhatsApp
    .split("\n")
    .map(encodeURIComponent)
    .join("%0A");
  const urlWhatsApp = `https://api.whatsapp.com/send?phone=+573122917124&text=${encodedString}`;

  const { mutate, isSuccess, isLoading, data } = useCreateOrder();

  const {
    mutate: createPreference,
    isSuccess: isSuccessPreference,
    isLoading: isLoadingPreference,
    isError: isErrorPreference,
    data: dataPreference,
  } = useCreatePreference();

  const {
    mutate: publish,
    isSuccess: isSuccessWS,
    isLoading: isLoadingWS,
    data: dataWS,
  } = usePublishWS();

  const {
    data: dataUser,
    isSuccess: isSuccessUser,
    isFetching,
  } = useGetUserByCellphone({ cellphone });

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Nombre es obligatorio"),
    lastName: Yup.string().required("Apellido es obligatorio"),
    email: Yup.string().required("Email es obligatorio"),
    department: Yup.object().required("Departamento es obligatorio"),
    city: Yup.string().required("Ciudad es obligatoria"),
    address: Yup.string().required("Dirección es obligatoria"),
    acceptTermsAndConditions: Yup.bool().oneOf(
      [true],
      "Acepte los Términos y Condiciones"
    ),
  });

  const { register, handleSubmit, control, errors, setValue } =
    useFormSubmit(validationSchema);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (id) {
      publish(id);
    }
  }, [id]);

  useEffect(() => {
    if (isSuccessWS) {
      setStock(dataWS?.stock);
    }
  }, [isLoadingWS]);

  useEffect(() => {
    if (id) {
      const ably = new Ably.Realtime(process.env.ABLY_API_KEY);

      const channel = ably.channels.get(id);

      channel.subscribe("example_event", (message) => {
        setStock(message?.data?.stock);
      });

      return () => {
        channel.unsubscribe();
      };
    }
  }, [id]);

  useEffect(() => {
    setLoad(true);
    if (isSuccessUser) {
      setLoad(false);
      if (dataUser) {
        setValue("firstName", dataUser?.firstName);
        setValue("lastName", dataUser?.lastName);
      }
    }
  }, [isFetching]);

  useEffect(() => {
    if (isSuccess) {
      setIdOrder(data?.order?.id);
      publish(id);
      window.scrollTo(0, 0);
    }
    if (isSuccessPreference) {
      setPreferenceId(dataPreference.url);
      window.scrollTo(0, 0);
    }
  }, [isLoading, isLoadingPreference]);

  const onSubmit = (data) => {
    const payload = { ...data };
    payload.cellPhone = cellphone;
    payload.department = payload.department.name_dep;
    payload.order = {
      quantity: count,
      unitPrice: pricePerTicket,
      paymentMethod: selectedValue,
    };
    payload.lotteryId = id;
    payload.order.paymentMethod = selectedValue;
    if (selectedValue !== "mercadopago") {
      mutate(payload);
    } else {
      createPreference(payload);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    if (typeof stock === "number" && stock < count) {
      window.confirm(
        "El número de boletas que seleccionó no está disponible, ¿desea regregar al detalle del sorteo y modificar la compra?"
      );
    }
  }, [stock]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <LayoutTheme titleOne="Proceso de" titleTwo="Compra">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          {isSuccess || isSuccessPreference ? (
            <Grid item xs={12}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h6" fontWeight={600}>
                  Resumen de Compra
                </Typography>
                {(isSuccess || isSuccessPreference) &&
                  selectedValue !== "mercadopago" && (
                    <Typography variant="body1">{`ID compra: ${
                      data?.order ? data.order?.id : ""
                    }`}</Typography>
                  )}
                {(isSuccess || isSuccessPreference) && (
                  <Typography variant="body1">{`Método pago: ${selectedValue}`}</Typography>
                )}
                <Typography variant="body1">{`${name} - ${count} entradas`}</Typography>
                <Typography
                  mb={1}
                  variant="body1"
                >{`Total: $${total?.toLocaleString()}`}</Typography>
                {selectedValue === "mercadopago" &&
                  isSuccessPreference &&
                  preferenceId && (
                    <>
                      <button
                        onClick={() => window.open(preferenceId, "_self")}
                        style={{
                          backgroundColor: "#0A66C2",
                          color: "white",
                          height: "40px",
                          width: "100%",
                          border: "none",
                          borderRadius: "10px",
                          marginTop: "16px",
                          cursor: "pointer",
                          fontSize: "20px",
                          fontWeight: 600,
                        }}
                      >
                        Pagar con Mercado Pago
                      </button>
                    </>
                  )}
                {selectedValue !== "mercadopago" && (
                  <Button
                    size="medium"
                    fullWidth
                    onClick={() => window.open(urlWhatsApp, "_blank")}
                    sx={{ marginTop: "16px" }}
                    variant="contained"
                    color="success"
                    startIcon={<WhatsAppIcon />}
                  >
                    Click aquí para efectuar tu pago
                  </Button>
                )}
                {selectedValue !== "mercadopago" && (
                  <Typography textAlign="center" mt={2}>
                    {String(`Serás redirigido a nuestro chat de ventas en WhastApp,
                          asegurate de dar click en "Ir al chat"`).toLocaleUpperCase()}
                  </Typography>
                )}
                {isErrorPreference && (
                  <Typography>
                    Ha ocurrido un error, por favor realice de nuevo el proceso
                  </Typography>
                )}
              </Paper>
            </Grid>
          ) : (
            <>
              {isMobile && !isSuccess && !isSuccessPreference && (
                <>
                  <Grid item xs={12}>
                    <Paper elevation={3} sx={{ padding: 2 }}>
                      <Typography variant="h6" fontWeight={600}>
                        Resumen de Compra
                      </Typography>
                      {(isSuccess || isSuccessPreference) &&
                        selectedValue !== "mercadopago" && (
                          <Typography variant="body1">{`ID compra: ${
                            data?.order ? data.order?.id : ""
                          }`}</Typography>
                        )}
                      {(isSuccess || isSuccessPreference) && (
                        <Typography variant="body1">{`Método pago: ${selectedValue}`}</Typography>
                      )}
                      <Typography variant="body1">{`${name} - ${count} entradas`}</Typography>
                      <Typography
                        mb={1}
                        variant="body1"
                      >{`Total: $${total?.toLocaleString()}`}</Typography>
                      {selectedValue === "mercadopago" &&
                        isSuccessPreference &&
                        preferenceId && (
                          <>
                            <button
                              onClick={() => window.open(preferenceId, "_self")}
                              style={{
                                backgroundColor: "#0A66C2",
                                color: "white",
                                height: "40px",
                                width: "100%",
                                border: "none",
                                borderRadius: "10px",
                                marginTop: "16px",
                                cursor: "pointer",
                                fontSize: "20px",
                                fontWeight: 600,
                              }}
                            >
                              Pagar con Mercado Pago
                            </button>
                          </>
                        )}
                    </Paper>
                  </Grid>
                  {/* )} */}
                </>
              )}

              {
                <Grid item xs={12} md={8}>
                  <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
                    <Typography mb={1} variant="h6" fontWeight={600}>
                      Información de Contacto
                    </Typography>
                    <UserForm
                      register={register}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      isLoading={isSuccessUser}
                      data={dataUser}
                      cellphone={cellphone}
                      setCellphone={setCellphone}
                      //handleSubmit={handleSubmit}
                      title={null}
                      disabledInputs={isSuccess || isSuccessPreference}
                      showTerms
                      errorMsg=""
                    />
                  </Paper>

                  {
                    <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
                      <Typography variant="h6" fontWeight={600}>
                        Método de Pago
                      </Typography>
                      <FormControl fullWidth margin="normal">
                        <div>
                          {total >= 5000 && stock >= 10000 && (
                            <Box display="flex" flexDirection="column">
                              <Typography fontWeight={600}>
                                Pasarela de Pago (PSE, Tarjetas de
                                crédito/debito, cuenta Mercado Pago):
                              </Typography>
                              <Box display="flex" justifyContent="center">
                                <Radio
                                  required
                                  checked={selectedValue === "mercadopago"}
                                  onChange={handleChange}
                                  value="mercadopago"
                                />
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  sx={{
                                    backgroundColor: "white",
                                    height: "60px",
                                    borderRadius: "8px",
                                    marginTop: "12px",
                                    padding: "0 8px",
                                  }}
                                >
                                  <MercadoPago />
                                </Box>
                              </Box>
                            </Box>
                          )}
                          <Box display="flex" flexDirection="column" mt={2}>
                            <Typography fontWeight={600}>
                              Transferencia manual enviando comprobante de pago:
                            </Typography>
                            <Box display="flex" justifyContent="center">
                              <Radio
                                required
                                checked={selectedValue === "nequi"}
                                onChange={handleChange}
                                value="nequi"
                              />
                              <Box
                                display="flex"
                                alignItems="center"
                                sx={{
                                  backgroundColor: "white",
                                  height: "60px",
                                  borderRadius: "8px",
                                  marginTop: "12px",
                                  padding: "0 8px",
                                }}
                              >
                                <Nequi />
                              </Box>
                            </Box>
                          </Box>

                          <Box display="flex" justifyContent="center">
                            <Radio
                              required
                              checked={selectedValue === "bancolombia"}
                              onChange={handleChange}
                              value="bancolombia"
                            />
                            <Box
                              display="flex"
                              alignItems="center"
                              sx={{
                                backgroundColor: "white",
                                height: "60px",
                                borderRadius: "8px",
                                marginTop: "12px",
                                padding: "0 8px",
                              }}
                            >
                              <Bancolombia />
                            </Box>
                          </Box>
                          <Box display="flex" justifyContent="center">
                            <Radio
                              required
                              checked={selectedValue === "daviplata"}
                              onChange={handleChange}
                              value="daviplata"
                            />
                            <Box
                              display="flex"
                              alignItems="center"
                              sx={{
                                backgroundColor: "white",
                                height: "60px",
                                borderRadius: "8px",
                                marginTop: "12px",
                                padding: "0 8px",
                              }}
                            >
                              <Daviplata />
                            </Box>
                          </Box>
                          {(!isSuccess || !isSuccessPreference) &&
                            selectedValue !== "" && (
                              <Button
                                disabled={
                                  selectedValue === "" ||
                                  isSuccess ||
                                  isSuccessPreference
                                }
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleSubmit(onSubmit)}
                                sx={{ marginTop: 2 }}
                              >
                                {isLoading || isLoadingPreference ? (
                                  <CircularProgress />
                                ) : (
                                  `Finalizar Compra`
                                )}
                              </Button>
                            )}
                          {Object.keys(errors).length > 0 && (
                            <Alert sx={{ mt: 1 }} severity="error">
                              Por favor completa el formulario anterior.
                            </Alert>
                          )}
                        </div>
                      </FormControl>
                    </Paper>
                  }
                </Grid>
              }

              {!isMobile && (
                <Grid item xs={12} md={4}>
                  <Paper elevation={3} sx={{ padding: 2 }}>
                    <Typography variant="h6" fontWeight={600}>
                      Resumen de Compra
                    </Typography>
                    {(isSuccess || isSuccessPreference) && (
                      <Typography variant="body1">{`ID compra: ${
                        data?.order ? data.order?.id : ""
                      }`}</Typography>
                    )}
                    {(isSuccess || isSuccessPreference) && (
                      <Typography variant="body1">{`Método pago: ${selectedValue}`}</Typography>
                    )}
                    <Typography variant="body1">{`${name} - ${count} entradas`}</Typography>
                    <Typography
                      mb={1}
                      variant="body1"
                    >{`Total: $${total?.toLocaleString()}`}</Typography>
                    {selectedValue === "mercadopago" &&
                      isSuccessPreference &&
                      preferenceId && (
                        <>
                          <button
                            onClick={() => window.open(preferenceId, "_self")}
                            style={{
                              backgroundColor: "#0A66C2",
                              color: "white",
                              height: "40px",
                              width: "100%",
                              border: "none",
                              borderRadius: "10px",
                              marginTop: "16px",
                              cursor: "pointer",
                              fontSize: "20px",
                              fontWeight: 600,
                            }}
                          >
                            Pagar con Mercado Pago
                          </button>
                        </>
                      )}
                    {(isSuccess || isSuccessPreference) &&
                      selectedValue !== "mercadopago" && (
                        <Button
                          size="medium"
                          fullWidth
                          onClick={() => window.open(urlWhatsApp, "_blank")}
                          sx={{ marginTop: "16px" }}
                          variant="contained"
                          color="success"
                          startIcon={<WhatsAppIcon />}
                        >
                          Click aquí para efectuar tu pago
                        </Button>
                      )}
                    {(isSuccess || isSuccessPreference) &&
                      selectedValue !== "mercadopago" && (
                        <Typography textAlign="center" mt={2}>
                          {String(`Serás redirigido a nuestro chat de ventas en WhastApp,
                          asegurate de dar click en "Ir al chat"`).toLocaleUpperCase()}
                        </Typography>
                      )}
                    {isErrorPreference && (
                      <Typography>
                        Ha ocurrido un error, por favor realice de nuevo el
                        proceso
                      </Typography>
                    )}
                  </Paper>
                </Grid>
              )}
            </>
          )}
        </Grid>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading || isLoadingPreference}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </LayoutTheme>
  );
};
