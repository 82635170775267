import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { LayoutTheme } from "@components";
import moment from "moment-timezone";
import html2pdf from "html2pdf.js";
import "./style.css";
import imageUrl from "../../assets/img/pdf.png";
import { useGetNumbers, useGetPurchases } from "@hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { jsPDF } from "jspdf";
import { useEffect, useRef, useState } from "react";

export const MyNumbers = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const cellphoneUrl = queryParams.get("cellphone");
  const [cellphone, setCellphone] = useState(cellphoneUrl ? cellphoneUrl : "");
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState(false);
  const [allNumbers, setAllNumbers] = useState([]);
  const lastElementRef = useRef(null);
  const { data, isLoading, isSuccess, refetch } = useGetPurchases({
    cellphoneUrl,
    cellphone,
    offset,
    search
  });

  const handleInputChange = (event) => {
    const value = event.target.value;
    queryParams.delete("cellphone");
    navigate({ search: queryParams.toString() }, { replace: true });
    setSearch(false);
    if (/^\d{0,10}$/.test(value)) {
      setCellphone(value);
    }
  };

  const handleSearch = () => {
    setOffset(0);
    queryParams.delete("cellphone");
    navigate({ search: queryParams.toString() }, { replace: true });
    setSearch(true);
    refetch();
  };

  const handleLoadMore = () => {
    setOffset((prevOffset) => prevOffset + 1);
  };

  useEffect(() => {
    if (data) {
      if (offset === 0) {
        setAllNumbers(data.rows);
      } else {
        setAllNumbers((prevNumbers) => [...prevNumbers, ...data.rows]);
      }
    }
  }, [data, offset]);

  useEffect(() => {
    if (lastElementRef.current && offset > 0) {
      lastElementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [allNumbers]);

  useEffect(() => {
    if (cellphone !== "") {
      refetch();
    }
  }, [offset]);

  useEffect(() => {
    if (cellphone === "") {
      setOffset(0);
    }
  }, [cellphone]);

  const getLabel = (status: string) => {
    switch (status) {
      case "rejected":
        return "Rechazado";
      case "pending":
        return "Pendiente";
      default:
        return "Aprobado";
    }
  };

  return (
    <LayoutTheme titleOne="Mis" titleTwo="Números">
      <Container>
        <Box my={4} display="flex" justifyContent="center">
          <TextField
            label="Celular"
            variant="outlined"
            value={cellphone}
            onChange={handleInputChange}
            size="small"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            disabled={cellphone.length !== 10}
            sx={{ marginLeft: 2 }}
          >
            Buscar
          </Button>
        </Box>

        {isLoading && (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        )}

        {data && (
          <Grid
            container
            spacing={2}
            padding={4}
            borderRadius={3}
            bgcolor="white"
          >
            {allNumbers.map((row, index) => (
              <Box display="flex" flexDirection="column">
                <Box display="flex" mb={2}>
                  <Typography fontWeight={600}>Sorteo:</Typography>
                  <Typography ml={2}>{row.lottery.name}</Typography>
                </Box>
                <Box display="flex" mb={2}>
                  <Typography fontWeight={600}>Estado de la compra:</Typography>
                  <Chip
                    sx={{ marginLeft: 2 }}
                    color={
                      row.status === "pending"
                        ? "warning"
                        : row.status === "rejected"
                        ? "error"
                        : "success"
                    }
                    size="small"
                    label={getLabel(row.status)}
                  />
                </Box>
                <Box display="flex" mb={2}>
                  <Typography fontWeight={600}>Cantidad de números:</Typography>
                  <Typography ml={2}>{row.quantity}</Typography>
                </Box>
                <Box display="flex" mb={2}>
                  <Typography fontWeight={600}>Fecha de compra:</Typography>
                  <Typography ml={2}>
                    {moment(row.createdAt)
                      .locale("es")
                      .format("DD/MMMM/YYYY - h:mm a")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      xs: "repeat(2, 1fr)", // 2 columnas en pantallas pequeñas
                      sm: "repeat(5, 1fr)", // 3 columnas en pantallas medianas
                      md: "repeat(10, 1fr)", // 4 columnas en pantallas grandes
                      lg: "repeat(10, 1fr)", // 5 columnas en pantallas extra grandes
                    },
                    gap: 2,
                    my: 4,
                  }}
                >
                  {row.tickets.map((e) => (
                    <Box
                      key={index}
                      p={2}
                      border={1}
                      borderRadius={2}
                      textAlign="center"
                      sx={{ fontWeight: 600 }}
                      ref={
                        index === allNumbers.length - data.rows.length
                          ? lastElementRef
                          : null
                      }
                    >
                      {e.item}
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
          </Grid>
        )}

        {data && (
          <Box textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleLoadMore}
              disabled={data.count === offset + 1}
            >
              Ver más
            </Button>
          </Box>
        )}
      </Container>
    </LayoutTheme>
  );
};
