import { Fab } from "@mui/material";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactElement, useEffect, useState } from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Outlet, useNavigate } from "react-router-dom";
import ComputerIcon from "@mui/icons-material/Computer";
import logo from "../../assets/img/rs.png";
import logo2 from "../../assets/img/rs2.png";

const logoStyle = {
  width: "140px",
  height: "auto",
  cursor: "pointer",
};

const SocialNetworks = () => {
  const msgWhatsApp = `¡Hola Rey de los Sueños!, estoy aquí para recibir más información. `;

  const encodedString = msgWhatsApp
    .split("\n")
    .map(encodeURIComponent)
    .join("%0A");
  const urlWhatsApp = `https://api.whatsapp.com/send?phone=+573122917124&text=${encodedString}`;
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "1rem",
        right: "1rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Fab
        href={urlWhatsApp}
        target="_blank"
        size="medium"
        sx={{ color: "#25D366", marginBottom: "0.2rem" }}
        aria-label="whatsapp"
      >
        <WhatsAppIcon />
      </Fab>
      <Fab
        href="https://www.instagram.com/reydelossuenos77/"
        target="_blank"
        size="medium"
        sx={{ color: "#C13584", marginBottom: "0.2rem" }}
        aria-label="instagram"
      >
        <InstagramIcon />
      </Fab>
      {/* <Fab
        href="https://www.instagram.com/reydelossuenos7/"
        target="_blank"
        size="medium"
        sx={{ color: "#3b5998" }}
        aria-label="facebook"
      >
        <FacebookIcon />
      </Fab> */}
    </Box>
  );
};

export const Home = (): ReactElement => {
  const navigate = useNavigate();
  const src = "../assets/img/rs";

  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId: string) => {
    navigate(sectionId);
  };

  return (
    <div>
      <div>
        <AppBar
          position="fixed"
          sx={{
            boxShadow: 0,
            bgcolor: "transparent",
            backgroundImage: "none",
            mt: 2,
          }}
        >
          <Container maxWidth="lg">
            <Toolbar
              variant="regular"
              sx={(theme) => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexShrink: 0,
                borderRadius: "999px",
                bgcolor:
                  theme.palette.mode === "light"
                    ? "rgba(255, 255, 255, 0.4)"
                    : "rgba(0, 0, 0, 0.4)",
                backdropFilter: "blur(24px)",
                maxHeight: 40,
                border: "1px solid",
                borderColor: "divider",
                boxShadow:
                  theme.palette.mode === "light"
                    ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                    : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
              })}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  alignItems: "center",
                  ml: "-18px",
                  px: 0,
                }}
              >
                <img src={logo} style={logoStyle} alt="logo of rs" />

                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                  <MenuItem
                    onClick={() => scrollToSection("/")}
                    sx={{ py: "6px", px: "12px" }}
                  >
                    <Typography variant="body2" color="text.primary">
                      Sorteos activos
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => scrollToSection("sorteos-concluidos")}
                    sx={{ py: "6px", px: "12px" }}
                  >
                    <Typography variant="body2" color="text.primary">
                      Sorteos concluidos
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => scrollToSection("mis-numeros")}
                    sx={{ py: "6px", px: "12px" }}
                  >
                    <Typography variant="body2" color="text.primary">
                      Mis números
                    </Typography>
                  </MenuItem>
                </Box>
              </Box>
              {/* <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  gap: 0.5,
                  alignItems: "center",
                }}
              >
                <Button
                  color="primary"
                  variant="text"
                  size="small"
                  component="a"
                  href="/admin/iniciar-sesion"
                  target="_blank"
                >
                  <ComputerIcon />
                </Button>
              </Box> */}
              <Box sx={{ display: { sm: "", md: "none" } }}>
                <Button
                  variant="text"
                  color="primary"
                  aria-label="menu"
                  onClick={toggleDrawer(true)}
                  sx={{ minWidth: "30px", p: "4px" }}
                >
                  <MenuIcon />
                </Button>
                <Drawer
                  anchor="right"
                  open={open}
                  onClose={toggleDrawer(false)}
                >
                  <Box
                    sx={{
                      minWidth: "60dvw",
                      p: 2,
                      backgroundColor: "background.paper",
                      flexGrow: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end",
                        flexGrow: 1,
                      }}
                    ></Box>
                    <MenuItem onClick={() => scrollToSection("/")}>
                      Sorteos activos
                    </MenuItem>
                    <MenuItem
                      onClick={() => scrollToSection("sorteos-concluidos")}
                    >
                      Sorteos concluidos
                    </MenuItem>
                    <MenuItem
                      onClick={() => scrollToSection("mis-numeros")}
                    >
                      Mis números
                    </MenuItem>
                    <Divider />
                    {/* <MenuItem>
                      <Button
                        color="primary"
                        variant="outlined"
                        component="a"
                        href="/admin/iniciar-sesion"
                        target="_blank"
                        sx={{ width: "100%" }}
                      >
                        <ComputerIcon />
                      </Button>
                    </MenuItem> */}
                  </Box>
                </Drawer>
              </Box>
            </Toolbar>
            {/* <div>
                  <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type a message..."
                  />
                  <button onClick={handleMessageSubmit}>Send</button>
                  <div>
                    <h2>Received Message:</h2>
                    <p>{receivedMessage}</p>
                  </div>
                </div> */}
          </Container>
        </AppBar>
      </div>
      <Outlet />
      <SocialNetworks />
    </div>
  );
};

export default Home;
